// import { firebase, admin, db, googleAuthProvider } from '../../firebase';
// import router from '../../router';
import Vue from 'vue'
import { storage } from '../../firebase'
import { filter, forEach, isEmpty } from 'lodash'

const state = {
  journeys: {},
  priceFilter: {},
  filters: {},
  orderBy: [
    {
      key: 'order',
      order: 'desc'
    }
  ]
}

const mutations = {
  setOrder(state, value) {
    Vue.set(state, 'orderBy', [value])
  },
  setPriceFilter(state, values) {
    Vue.set(state.priceFilter, 'min', values[0])
    Vue.set(state.priceFilter, 'max', values[1])
  },
  toggleFilter(state, filter) {
    let currentFilter = state.filters[filter.key]

    if (!currentFilter) {
      currentFilter = {
        key: filter.key,
        operator: filter.operator,
        values: []
      }
    }

    if (currentFilter.values.indexOf(filter.value) === -1) {
      currentFilter.values.push(filter.value)
    } else {
      currentFilter.values.splice(currentFilter.values.indexOf(filter.value), 1)
    }

    Vue.set(state.filters, filter.key, currentFilter)
  },
  removeFilter(state, filter) {
    let currentFilter = state.filters[filter.key]

    if (!currentFilter) {
      currentFilter = {
        key: filter.key,
        operator: filter.operator,
        values: []
      }
    }
    currentFilter.values.splice(currentFilter.values.indexOf(filter.value), 1)

    Vue.set(state.filters, filter.key, currentFilter)
  },
  resetFilters(state) {
    state.filters = {}
    state.priceFilter = {}
    console.log('RESET')
  }
}

const actions = {
  async addProduct({ dispatch }, product) {
    const id = await dispatch('insert', {
      brand: product.brand,
      name: product.name,
      shorturl: product.shorturl,
      capacity: product.capacity,
      colour: product.colour,
      live: false
    })

    if (product.image.constructor.name === 'File') {
      dispatch('addImage', { id, image: product.image })
    } else {
      dispatch('patch', {
        id,
        image: product.image
      })
    }
  },
  addImage({ dispatch }, { id, image }) {
    const storageRef = storage.ref(`product/${id}.jpg`).put(image)
    storageRef.on(
      'state_changed',
      snapshot => {
        console.log('stage_changed')
        this.uploadValue =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      },
      error => {
        console.log('error', error)
      },
      () => {
        this.uploadValue = 100
        storageRef.snapshot.ref.getDownloadURL().then(image => {
          dispatch('set', { id, image: image })
        })
      }
    )
  },
  async editProduct({ dispatch }, product) {
    console.log('image', product.image.constructor.name, product.image)
    dispatch('patch', {
      id: product.id,
      brand: product.brand,
      name: product.name,
      shorturl: product.shorturl,
      capacity: product.capacity,
      colour: product.colour
    })

    if (product.image.constructor.name === 'File') {
      dispatch('addImage', { id: product.id, image: product.image })
    }
  },
  deleteProduct({ dispatch }, productId) {
    console.log('delete', productId)
    dispatch('delete', productId)
  },
  toggleLive({ dispatch, state }, productId) {
    dispatch('patch', {
      id: productId,
      live: !state.products[productId].live
    })
  },
  setOrder({ commit }, value) {
    console.log('SET ORDER', value)
    commit('setOrder', value)
  },
  setPriceFilter({ commit }, values) {
    console.log('SET PRICE', values)
    commit('setPriceFilter', values)
  },
  toggleFilter({ commit }, filter) {
    commit('toggleFilter', filter)
  },
  resetFilters({ commit }) {
    commit('resetFilters')
  }
}

const getters = {
  getFilteredProducts(state, getters, rootState, rootGetters) {
    let products = state.products

    products = filter(products, product => {
      return product.live === true
    })

    products.forEach((product, index) => {
      products[index] = {
        ...product,
        ...rootGetters['deal/getProductDealsInfo'](product.id)
      }
    })
    console.log('getfilted', state.priceFilter.min, state.priceFilter.max)
    if (!isEmpty(state.priceFilter)) {
      console.log('pricefilter', state.priceFilter)
      products = filter(
        products,
        product =>
          product.price >= state.priceFilter.min &&
          product.price <= state.priceFilter.max
      )
    }

    forEach(state.filters, f => {
      if (f.values.length) {
        products = filter(products, product => {
          switch (f.operator) {
            case '=':
              // console.log(
              //   'product',
              //   f.key,
              //   f.values,
              //   f.values.indexOf(product[f.key])
              // );
              return f.values.indexOf(product[f.key]) !== -1
            case '<':
              // console.log('<', f.key, product[f.key], f.values);
              return f.values.some(el => product[f.key] < el)
            case '>':
              // console.log('>', f.key, product[f.key], f.values);
              return f.values.some(el => product[f.key] > el)
          }
        })
      }
    })

    return products

    // return state.filters.length ? filteredProducts : products;
  },
  getLiveProducts(state) {
    const products = {}
    Object.keys(state.products).forEach(productId => {
      if (state.products[productId].live) {
        products[productId] = state.products[productId]
      }
    })
    return products
  },
  getProductsMinAndMaxPrice(state, getters) {
    // let products = state.products;

    // forEach(products, (product, index) => {
    //   products[index] = {
    //     ...product,
    //     ...rootGetters['deal/getProductDealsInfo'](product.id)
    //   };
    // });

    const products = getters.getFilteredProducts

    return [
      // Math.min(
      Math.min(...products.map(product => parseFloat(product.price))),
      // ),

      Math.max(...products.map(product => parseFloat(product.price)))
    ]
  },
  getProduct(state) {
    return id => state.products[id]
  },
  getProductByShorturl(state) {
    return shorturl => {
      const product = Object.keys(state.products).filter(
        productId => state.products[productId].shorturl === shorturl
      )

      if (product.length) {
        return state.products[product[0]]
      }
    }
  },
  getImage(state) {
    return id => {
      if (state.products[id]) {
        return state.products[id].image
      }
    }
  },
  getCategories(state) {
    return category => {
      return Array.from(
        new Set(
          Object.keys(state.products)
            .filter(productId => !!state.products[productId][category])
            .map(productId => state.products[productId][category])
        )
      )
    }
  },
  getBrands(state) {
    return Array.from(
      new Set(
        Object.keys(state.products)
          .filter(productId => !!state.products[productId].brand)
          .map(productId => state.products[productId].brand)
      )
    )
  },
  getNames(state, getters) {
    return getters.getCategories('name')
  },
  getColours(state) {
    return Array.from(
      new Set(
        Object.keys(state.products)
          .filter(productId => !!state.products[productId].colour)
          .map(productId => state.products[productId].colour)
      )
    )
  },
  getCapacities(state) {
    return Array.from(
      new Set(
        Object.keys(state.products)
          .filter(productId => !!state.products[productId].capacity)
          .map(productId => state.products[productId].capacity)
      )
    )
  }
}

export default {
  firestorePath: 'journeys/drafts/content',
  firestoreRefType: 'collection',
  moduleName: 'draftJourneys',
  statePropName: 'journeys',
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
