<template>
  <div class="input">
    <select
      class="input__element"
      :class="{ 'input__element--placeholder': !modelValue }"
      :value="modelValue"
      @change="updateInput"
    >
      <option v-if="!hidePlaceholder" class="input__placeholder" :value="null">
        {{ placeholder }}
      </option>
      <option
        v-for="(option, index) in options"
        :key="index"
        :value="option.value ? option.value : option.text"
      >
        {{ option.text }}
      </option>
    </select>
    <i class="fal fa-sort" />
    <i v-if="false && value && !hasError" class="fal fa-check-circle" />
    <i v-if="false && hasError" class="fal fa-times-circle" />
    <p v-if="hasError" class="error">
      {{ errorMsg }}
    </p>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    placeholder: {
      type: String,
      default: 'Please select an option'
    },
    hidePlaceholder: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      value: false
    },
    value: {
      type: String,
      default: null
    },
    modelValue: {
      type: String,
      default: null
    },
    vError: {
      type: Boolean,
      default: function() {
        return false
      }
    },
    hasError: {
      type: Boolean
    },
    errorMsg: {
      type: String,
      default: null
    }
  },
  methods: {
    updateInput(event) {
      this.$emit('change', event.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.input {
  position: relative;

  .fal,
  .fas {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 1.6rem;
    padding: 1em 1.6rem;
    color: $color-secondary;

    &.fa-check-circle {
      color: $color-tertiary;
    }
  }

  .error {
    margin: 2px 0;
    color: $color-secondary;
  }
}

.input__element {
  display: block;
  background: transparent;
  width: 100%;
  border: none;
  border-bottom: 1px solid $color-gray;
  padding: 0 1.6rem;
  line-height: 4.8rem;
  height: 4.8rem;
  font-size: 1.6rem;
  font-weight: 500;
  color: $color-gray--darker;
  border-radius: $border-radius-small;

  &:disabled {
    color: $color-gray;
  }

  &--placeholder {
    font-weight: 400;
    font-style: italic;
    color: $color-gray--dark;
  }
}
</style>
