<template>
  <div
    class="content"
    :class="classes"
    :style="{ 'background-color': background }"
  >
    <section class="content__container">
      <header v-if="title">
        <i :class="`fal fa-${icon}`" />
        <h1>{{ title }}</h1>
      </header>
      <Divider v-if="title" type="black" />
      <article>
        <slot />
      </article>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Content',
  props: {
    title: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    align: {
      type: String,
      default: null
    },
    background: {
      type: String,
      default: null
    }
  },
  computed: {
    classes: function() {
      return {
        'content--align-center': this.align === 'center'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  max-width: 1170px;
  position: relative;
  margin-right: auto;
  padding: 2.4rem $padding;
  max-width: 100%;

  &--align-center {
    margin-left: auto;
    margin-right: auto;
  }
}

.content__container {
  color: $color-black;
  font-size: 1.8rem;
  font-weight: 300;

  margin: auto;
}

article {
  .signature {
    text-align: right;
    font-family: 'Dancing Script', cursive;
    font-size: 2.2rem;
  }
}

header {
  text-align: center;
  margin-bottom: 4rem;

  i {
    font-size: 5rem;
  }

  h1 {
    font-family: 'Dancing Script', cursive;
    font-size: 5rem;
    line-height: 6rem;
    margin-bottom: 1.6rem;
  }
}
</style>
