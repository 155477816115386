import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import store from './store'

// firebase init goes here
const config = {
  apiKey: 'AIzaSyDao6_kj1rYUVurMTVOYamanBwLwt5GGzM',
  authDomain: 'sustainabl-d1b01.firebaseapp.com',
  projectId: 'sustainabl-d1b01',
  storageBucket: 'sustainabl-d1b01.appspot.com',
  messagingSenderId: '866449237401',
  appId: '1:866449237401:web:d9d8163e58ea693cec1f08',
  measurementId: 'G-Z4QCLGMBJD'
}

firebase.initializeApp(config)

let hasUser = false
const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    if (hasUser) {
      resolve(store.state.user.currentUser)
      return
    }

    const unsubscribe = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        unsubscribe()

        await store.dispatch('user/fetchAndAdd')
        hasUser = true

        resolve(user)
        return
      }
      resolve()
    }, reject)
  })
}

const sendPasswordResetEmail = email => {
  return new Promise((resolve, reject) => {
    if (!email) {
      reject('missing_email')
      return
    }

    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        resolve('sent')
      })
      .catch(error => {
        reject(error)
      })
  })
}

const db = firebase.firestore()
const storage = firebase.storage()
const googleAuthProvider = new firebase.auth.GoogleAuthProvider()
export {
  firebase,
  db,
  storage,
  googleAuthProvider,
  getCurrentUser,
  sendPasswordResetEmail
}
