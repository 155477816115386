const state = {
  surveyResponses: []
}

const mutations = {}

const actions = {}

const getters = {}

export default {
  firestorePath: 'survey-responses',
  firestoreRefType: 'collection',
  moduleName: 'surveyResponses',
  statePropName: 'surveyResponses',
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
