<template>
  <div class="input">
    <input
      type="text"
      :name="name"
      class="input__element"
      :value="value"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      readonly
      onfocus="if (this.hasAttribute('readonly')) {
        this.removeAttribute('readonly');
        this.blur(); 
        this.focus(); 
      }"
      @change="$emit('change', $event.target.value)"
      @input="$emit('input', $event.target.value)"
    />
    <i v-if="false && value && !hasError" class="fal fa-check-circle" />
    <i v-if="false && hasError" class="fal fa-times-circle" />
    <p v-if="hasError" class="error">
      {{ errorMsg }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: 'input'
    },
    inputChanged: {
      type: Function,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      value: false
    },
    value: {
      type: [String, Number],
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    hasError: {
      type: Boolean
    },
    errorMsg: {
      type: String,
      default: null
    },
    autocomplete: {
      type: String,
      default: 'on'
    }
  }
}
</script>

<style lang="scss" scoped>
.input {
  position: relative;

  .fal {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 1.6rem;
    padding: 0.8em 1.6rem;
    line-height: 1.9rem;
    color: $color-secondary;

    &.fa-check-circle {
      color: $color-success;
    }
  }

  .error {
    margin: 2px 0;
    color: $color-secondary;
  }
}

.input__element {
  display: block;
  background: transparent;
  width: 100%;
  border: none;
  border-bottom: 1px solid $color-gray;
  padding: 0 1.6rem;
  line-height: 4.8rem;
  height: 4.8rem;
  font-size: 1.6rem;
  font-weight: 500;
  color: $color-gray--darker;
  border-radius: $border-radius-small;
  &:disabled {
    color: $color-gray;
  }

  &::placeholder {
    font-weight: 400;
    font-style: italic;
    color: $color-gray--dark;
  }
}

.input__textarea {
  height: 200px;
}
</style>
