import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
// import Login from './views/Login';
import { getCurrentUser } from './firebase'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/login',
      component: () => import('./views/Login.vue')
    },
    {
      path: '/forgot-password',
      component: () => import('./views/layouts/Page.vue'),
      children: [
        {
          path: '',
          meta: {
            title: 'Forgot password',
            background: 'white'
          },
          component: () => import('./views/ForgotPassword.vue')
        }
      ]
    },
    {
      path: '/sign-up',
      component: () => import('./views/Login.vue')
    },
    {
      path: '/',
      redirect: '/journeys/0/levels',
      meta: {
        authRequired: true
      }
    },
    {
      path: '/components',
      meta: {
        authRequired: true
      },
      component: () => import('./views/layouts/PageWithHeader.vue'),
      children: [
        {
          path: '',
          meta: {
            title: 'Components',
            background: 'white'
          },
          component: () => import('./views/Components.vue')
        }
      ]
    },
    {
      path: '/onboarding',
      meta: {
        authRequired: true,
        title: 'Onboarding',
        background: 'white'
      },
      component: () => import('./views/layouts/Page.vue'),
      children: [
        {
          path: '',
          meta: {
            title: 'Onboarding',
            background: 'white'
          },
          component: () => import('./views/Onboarding.vue')
        }
      ]
    },
    {
      path: '/journeys/:journeyId/levels',
      meta: {
        authRequired: true
      },
      component: () => import('./views/layouts/Page.vue'),
      children: [
        {
          path: '',
          meta: {
            title: 'Levels',
            background: 'white'
          },
          component: () => import('./views/journeys/Levels.vue')
        }
      ]
    },
    {
      path: '/journeys/:journeyId/summary',
      meta: {
        authRequired: true
      },
      component: () => import('./views/layouts/Page.vue'),
      children: [
        {
          path: '',
          meta: {
            title: 'Levels',
            background: 'white'
          },
          component: () => import('./views/journeys/Summary.vue')
        }
      ]
    },
    {
      path: '/journeys/:journeyId/levels/:levelId',
      meta: {
        authRequired: true
      },
      component: () => import('./views/layouts/Page.vue'),
      children: [
        {
          path: '',
          meta: {
            background: 'white'
          },
          component: () => import('./views/journeys/Level.vue')
        }
      ]
    },
    {
      path: '/challenges/:challengeId',
      meta: {
        authRequired: true
      },
      component: () => import('./views/layouts/Page.vue'),
      children: [
        {
          path: '',
          meta: {
            background: 'white'
          },
          component: () => import('./views/challenges/Challenge.vue')
        }
      ]
    },
    {
      path: '/journeys',
      meta: {
        authRequired: true
      },
      component: () => import('./views/layouts/PageWithHeader.vue'),
      children: [
        {
          path: '',
          meta: {
            title: 'Journeys',
            background: 'white'
          },
          component: () => import('./views/Journeys.vue')
        }
      ]
    },
    {
      path: '/challenges',
      meta: {
        authRequired: true
      },
      component: () => import('./views/layouts/PageWithHeader.vue'),
      children: [
        {
          path: '',
          meta: {
            title: 'Challenges',
            background: 'white'
          },
          component: () => import('./views/Challenges.vue')
        }
      ]
    },
    {
      path: '/leaderboard',
      meta: {
        authRequired: true
      },
      component: () => import('./views/layouts/Page.vue'),
      children: [
        {
          path: '',
          meta: {
            title: 'Leaderboard',
            background: 'white'
          },
          component: () => import('./views/Leaderboard.vue')
        }
      ]
    },
    {
      path: '/ideas',
      meta: {
        authRequired: true
      },
      component: () => import('./views/layouts/Page.vue'),
      children: [
        {
          path: '',
          meta: {
            title: 'Ideas Wall',
            background: 'white'
          },
          component: () => import('./views/Ideas.vue')
        }
      ]
    },
    {
      path: '/account',
      meta: {
        authRequired: true
      },
      component: () => import('./views/layouts/Page.vue'),
      children: [
        {
          path: '',
          meta: {
            title: 'Account',
            background: 'white'
          },
          component: () => import('./views/Account.vue')
        }
      ]
    },
    {
      path: '/not-verified',
      component: () => import('./views/layouts/PageWithHeader.vue'),
      children: [
        {
          path: '',
          meta: {
            title: 'Not Verified',
            background: 'white'
          },
          component: () => import('./views/NotVerified.vue')
        }
      ]
    },
    {
      path: '/privacy',
      component: () => import('./views/layouts/PageWithHeader.vue'),
      children: [
        {
          path: '',
          meta: {
            title: 'Privacy Policy',
            background: 'white'
          },
          component: () => import('./views/Privacy.vue')
        }
      ]
    },
    {
      path: '/terms',
      component: () => import('./views/layouts/PageWithHeader.vue'),
      children: [
        {
          path: '',
          meta: {
            title: 'Terms of Service',
            background: 'white'
          },
          component: () => import('./views/Terms.vue')
        }
      ]
    },
    {
      path: '/admin',
      component: () => import('./views/layouts/PageWithHeader.vue'),
      meta: {
        authRequired: true
      },
      children: [
        {
          path: '',
          meta: {
            title: 'Admin',
            background: 'white'
          },
          component: () => import('./views/admin/Overview.vue')
        }
      ]
    },
    {
      path: '/journey/add',
      component: () => import('./views/admin/AddJourney.vue'),
      meta: {
        authRequired: true
      }
    },
    {
      path: '/journey/edit/:id',
      component: () => import('./views/admin/AddJourney.vue'),
      meta: {
        authRequired: true
      }
    },
    {
      path: '/level/add',
      component: () => import('./views/admin/AddLevel.vue'),
      meta: {
        authRequired: true
      }
    },
    {
      path: '/level/edit/:id',
      component: () => import('./views/admin/AddLevel.vue'),
      meta: {
        authRequired: true
      }
    },
    {
      path: '*',
      component: () => import('./views/layouts/PageWithHeader.vue'),
      children: [
        {
          path: '',
          meta: {
            title: 'Page not found',
            background: 'white'
          },
          component: () => import('./views/404.vue')
        }
      ]
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.progress !== undefined) {
    let meta = to.meta.progress
    router.app.$Progress.parseMeta(meta)
  }
  router.app.$Progress.start()

  const user = await getCurrentUser()
  const isAdmin = await store.getters['user/isAdmin']
  const isVerified = await store.getters['user/isVerified']
  const hasOnboarded = await store.getters['user/hasOnboarded']

  if (to.matched.some(record => record.meta.adminRequired)) {
    if (!user) {
      next('/login')
      return
    }

    if (!isAdmin) {
      next('/404')
      return
    }
  }

  if (to.matched.some(record => record.meta.authRequired)) {
    if (!user) {
      next('/login')
      return
    }

    if (!isVerified) {
      next('/not-verified')
      return
    }

    if (!hasOnboarded && to.path !== '/onboarding') {
      next('/onboarding')
      return
    }
  }

  if (store.state.app.isBurgerMenuOpen) {
    store.dispatch('app/routeChange')
  }

  next()
})

router.afterEach(() => {
  router.app.$Progress.finish()
})

export default router
