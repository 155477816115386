<template>
  <section class="card" :class="classes" :style="styles">
    <a v-if="image" href="#" class="image featured" />
    <header v-if="title">
      <h3>{{ title }}</h3>
    </header>
    <slot />
    <footer v-if="false">
      <a href="#" class="button alt">Magna sed taciti</a>
    </footer>
  </section>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    size: {
      type: [String, Number],
      default: null
    },
    grow: {
      type: Boolean,
      default: false
    },
    alignSelf: {
      type: String,
      default: null
    },
    textAlign: {
      type: String,
      default: null
    },
    padding: {
      type: String,
      default: null
    }
  },
  computed: {
    classes: function() {
      return {
        'flex-item--grow': this.grow,
        'flex-item__align-self--flex-end': this.alignSelf === 'flex-end',
        'flex-item__align-self--center': this.alignSelf === 'center',
        'flex-item__align-self--stretch': this.alignSelf === 'stretch',
        'flex-item__text-align--center': this.textAlign === 'center',
        'flex-item__padding': this.padding === 'normal',
        'flex-item__padding--large': this.padding === 'large'
      }
    },
    styles: function() {
      return {
        width: `${this.size}`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  background: #f7f8f3;
  color: $color-black;
  padding: $padding;
  border-radius: $border-radius;

  .image.featured {
    position: relative;
    width: auto;
    left: 1.75rem;
    top: 1.75rem;
    margin: -4.5rem 0 4.25rem -3.5rem;

    img {
      width: 100%;
    }
  }

  &.post header {
    padding-top: 1em;
    margin: 0 0 2em 0;
  }

  &.post h2 {
    font-size: 2.5em;
    letter-spacing: -0.015em;
  }

  &.post header > p {
    margin-top: 1.25em;
    font-size: 1.25em;
  }

  footer ul.actions {
    margin-bottom: 0;
  }
}
</style>
