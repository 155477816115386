import Vue from 'vue'
import Vuex from 'vuex'
import VuexEasyFirestore from 'vuex-easy-firestore'
import app from './modules/app'
import user from './modules/user'
import journeys from './modules/journeys'
import draftJourneys from './modules/draftJourneys'
import journey from './modules/journey'
import journeysMetadata from './modules/journeysMetadata'
import levels from './modules/levels'
import levelContentBlocks from './modules/levelContentBlocks'
import challenge from './modules/challenge'
import challenges from './modules/challenges'
import challengeContentBlocks from './modules/challengeContentBlocks'
import level from './modules/level'
import surveyResponses from './modules/surveyResponses'
import quizResponses from './modules/quizResponses'
import ideas from './modules/ideas'
// import createLogger from "../../../src/plugins/logger";

import { firebase } from '../firebase'

Vue.use(Vuex)

const easyFirestore = VuexEasyFirestore(
  [
    user,
    levelContentBlocks,
    challenge,
    challenges,
    challengeContentBlocks,
    journeys,
    draftJourneys,
    journey,
    journeysMetadata,
    ideas,
    levels,
    level,
    surveyResponses,
    quizResponses
  ],
  {
    logging: true,
    FirebaseDependency: firebase
  }
)

export default new Vuex.Store({
  plugins: [easyFirestore],
  modules: {
    app
  },
  // strict: debug
  strict: false
  // plugins: debug ? [createLogger()] : []
})
