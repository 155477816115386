import _ from 'lodash'

const state = {
  challenge: {}
}

const mutations = {}

const actions = {}

const getters = {
  getChallenge(state) {
    return _.cloneDeep(state.challenge)
  }
}

export default {
  firestorePath: 'challenges/{challengeId}',
  firestoreRefType: 'doc',
  moduleName: 'challenge',
  statePropName: 'challenge',
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
