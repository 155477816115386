const state = {
  quizResponses: []
}

const mutations = {}

const actions = {}

const getters = {}

export default {
  firestorePath: 'quiz-responses',
  firestoreRefType: 'collection',
  moduleName: 'quizResponses',
  statePropName: 'quizResponses',
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
