<template>
  <a v-scroll-to="href" :href="href" class="button" :class="classes">
    <slot>ButtonScroll</slot>
  </a>
</template>

<script>
import Button from '@/components/global/ui/Button.vue'
export default {
  extends: Button,
  props: {
    href: {
      type: String,
      default: null
    }
  }
}
</script>
