<template>
  <div type="alert" class="alert" :class="classes">
    <i v-if="icon" :class="`fal fa-${icon}`" />
    <span :class="icon ? 'u__margin-left' : ''">
      <span>
        <slot>alert</slot>
      </span>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      validator: function(value) {
        return (
          [
            'primary',
            'secondary',
            'tertiary',
            'quaternary',
            'quinary',
            'success',
            'error',
            'transparent'
          ].indexOf(value) !== -1
        )
      },
      default: 'primary'
    },
    icon: {
      type: String,
      default: null
    },
    fullWidth: {
      type: [Boolean, String],
      default: false
    },
    size: {
      type: String,
      validator: function(value) {
        return ['small', 'medium', 'large'].indexOf(value) !== -1
      },
      default: 'medium'
    }
  },
  computed: {
    classes: function() {
      return {
        'alert--primary': this.type === 'primary',
        'alert--secondary': this.type === 'secondary',
        'alert--tertiary': this.type === 'tertiary',
        'alert--quaternary': this.type === 'quaternary',
        'alert--quinary': this.type === 'quinary',
        'alert--transparent': this.type === 'transparent',
        'alert--success': this.type === 'success',
        'alert--error': this.type === 'error',
        'alert--full-width': this.fullWidth,
        'alert--small': this.size === 'small',
        'alert--large': this.size === 'large'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.alert {
  appearance: none;
  display: inline-block;
  position: relative;
  font-weight: 400;
  letter-spacing: 0.075em;
  text-align: center;
  text-decoration: none;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.25;
  border-radius: 1.8rem;
  padding: 1.6rem 3rem;
  background-color: transparent;
  border: 1px solid $color-secondary;
  color: $color-secondary;
  transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
    color 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    border: 1px solid $color-primary;
    color: $color-primary;
  }

  &--small {
    font-size: 1.2rem;
    height: 3.8rem;
    line-height: 3.8rem;
    border-radius: 3.8rem;
    padding: 0 1.6rem;
  }

  &--large {
    font-size: 1.8rem;
    height: 4.8rem;
    line-height: 4.8rem;
    border-radius: 4.8rem;
    padding: 0 3rem;
  }

  &--primary {
    background-color: $color-primary;
    border: none;
    color: #fff;
    box-shadow: none;

    &:hover {
      background-color: $color-primary--dark;
      border: none;
      color: #fff;
    }
  }

  &--secondary {
    background-color: $color-secondary;
    border: none;
    color: #fff;
    box-shadow: none;

    &:hover {
      background-color: $color-secondary--dark;
      color: #fff;
      border: none;
      box-shadow: none;
    }
  }

  &--tertiary {
    background-color: $color-tertiary;
    border: none;
    color: $color-black;
    box-shadow: none;

    &:hover {
      background-color: $color-tertiary--dark;
      border: none;
      color: $color-black;
    }
  }

  &--quaternary {
    background-color: #f7f8f3;
    border: none;
    color: $color-black;
    box-shadow: none;

    &:hover {
      background-color: #dbdbdb;
      border: none;
      color: $color-black;
    }
  }

  &--quinary {
    background-color: #bbe2e2;
    border: none;
    color: $color-black;
    box-shadow: none;

    &:hover {
      background-color: $color-primary;
      border: none;
      color: $color-black;
    }
  }

  &--success {
    background-color: $color-success;
    border: none;
    color: #fff;
    box-shadow: none;

    &:hover {
      background-color: $color-success--dark;
      border: none;
      color: #fff;
    }
  }

  &--error {
    background-color: $color-error;
    border: none;
    color: #fff;
    box-shadow: none;

    &:hover {
      background-color: $color-error--dark;
      border: none;
      color: #fff;
    }
  }

  &--transparent {
    background-color: transparent;
    border: 1px solid $color-secondary;
    color: $color-secondary;
    box-shadow: none;

    &:hover {
      background-color: $color-primary--dark;
      border: 1px solid $color-primary;
      color: #fff;
    }
  }

  &:disabled,
  &[disabled='disabled'] {
    background-color: $color-gray;
    opacity: 0.75;
  }

  &--full-width {
    width: 100%;
  }

  .fal {
    font-size: 2rem;
    margin-right: 0.5rem;
    position: absolute;
    left: 2rem;
    top: 50%;
    transform: translateY(-50%);
  }

  .fa-spin {
    font-size: 16px;
    width: 16px;
    height: 16px;
    animation: fa-spin 1s infinite linear;
  }

  @include respond-below(sm) {
    width: 100%;
    max-width: 100%;
  }
}
</style>
