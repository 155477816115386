// import { firebase, admin, db, googleAuthProvider } from '../../firebase';
// import router from '../../router';
import Vue from 'vue'
import { storage } from '../../firebase'
import _, { filter, forEach, isEmpty } from 'lodash'

const state = {
  journeys: {},
  priceFilter: {},
  filters: {},
  orderBy: [
    {
      key: 'order',
      order: 'desc'
    }
  ]
}

const mutations = {
  setOrder(state, value) {
    Vue.set(state, 'orderBy', [value])
  },
  setPriceFilter(state, values) {
    Vue.set(state.priceFilter, 'min', values[0])
    Vue.set(state.priceFilter, 'max', values[1])
  },
  toggleFilter(state, filter) {
    let currentFilter = state.filters[filter.key]

    if (!currentFilter) {
      currentFilter = {
        key: filter.key,
        operator: filter.operator,
        values: []
      }
    }

    if (currentFilter.values.indexOf(filter.value) === -1) {
      currentFilter.values.push(filter.value)
    } else {
      currentFilter.values.splice(currentFilter.values.indexOf(filter.value), 1)
    }

    Vue.set(state.filters, filter.key, currentFilter)
  },
  removeFilter(state, filter) {
    let currentFilter = state.filters[filter.key]

    if (!currentFilter) {
      currentFilter = {
        key: filter.key,
        operator: filter.operator,
        values: []
      }
    }
    currentFilter.values.splice(currentFilter.values.indexOf(filter.value), 1)

    Vue.set(state.filters, filter.key, currentFilter)
  },
  resetFilters(state) {
    state.filters = {}
    state.priceFilter = {}
    console.log('RESET')
  }
}

const actions = {
  async addJourney({ dispatch }, journey) {
    console.log('addJourney')
    const id = await dispatch('insert', {
      name: journey.name,
      subtitle: journey.subtitle,
      description: journey.description,
      savings: journey.savings,
      taxRelief: journey.taxRelief
    })

    if (journey.imagePath.constructor.name === 'File') {
      dispatch('addImage', { id, image: journey.imagePath })
    } else {
      dispatch('patch', {
        id,
        imagePath: journey.imagePath
      })
    }

    if (journey.headerImagePath.constructor.name === 'File') {
      dispatch('addHeaderImage', {
        id: journey.id,
        image: journey.headerImagePath
      })
    } else {
      dispatch('patch', {
        id,
        headerImagePath: journey.headerImagePath
      })
    }
  },
  addImage({ dispatch }, { id, image }) {
    console.log('addImage', image)
    const storageRef = storage.ref(`journey/${id}.jpg`).put(image)
    storageRef.on(
      'state_changed',
      snapshot => {
        console.log('stage_changed')
        this.uploadValue =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      },
      error => {
        console.log('error', error)
      },
      () => {
        this.uploadValue = 100
        console.log('upload100', image)
        storageRef.snapshot.ref.getDownloadURL().then(image => {
          dispatch('set', { id, imagePath: image })
        })
      }
    )
  },
  addHeaderImage({ dispatch }, { id, image }) {
    const storageRef = storage.ref(`level/${id}-header.jpg`).put(image)
    storageRef.on(
      'state_changed',
      snapshot => {
        console.log('stage_changed')
        this.uploadValue =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      },
      error => {
        console.log('error', error)
      },
      () => {
        this.uploadValue = 100
        storageRef.snapshot.ref.getDownloadURL().then(image => {
          dispatch('set', { id, headerImagePath: image })
        })
      }
    )
  },
  async editProduct({ dispatch }, product) {
    console.log('image', product.image.constructor.name, product.image)
    dispatch('patch', {
      id: product.id,
      brand: product.brand,
      name: product.name,
      shorturl: product.shorturl,
      capacity: product.capacity,
      colour: product.colour
    })

    if (product.image.constructor.name === 'File') {
      dispatch('addImage', { id: product.id, image: product.image })
    }
  },
  deleteProduct({ dispatch }, productId) {
    console.log('delete', productId)
    dispatch('delete', productId)
  },
  toggleLive({ dispatch, state }, productId) {
    dispatch('patch', {
      id: productId,
      live: !state.products[productId].live
    })
  },
  setOrder({ commit }, value) {
    console.log('SET ORDER', value)
    commit('setOrder', value)
  },
  setPriceFilter({ commit }, values) {
    console.log('SET PRICE', values)
    commit('setPriceFilter', values)
  },
  toggleFilter({ commit }, filter) {
    commit('toggleFilter', filter)
  },
  resetFilters({ commit }) {
    commit('resetFilters')
  }
}

const getters = {
  getJourneys(state) {
    const _journeys = _.cloneDeep(state.journeys)
    // for (const journeyId in _journeys) {
    //   if (Object.hasOwnProperty.call(_journeys, journeyId)) {
    //     const _journey = _journeys[journeyId]
    // TODO: Need to do something with importing images
    //     _journey.image = _journey.imagePath
    //   }
    // }
    return _journeys
  },
  getFilteredProducts(state, getters, rootState, rootGetters) {
    let products = state.products

    products = filter(products, product => {
      return product.live === true
    })

    products.forEach((product, index) => {
      products[index] = {
        ...product,
        ...rootGetters['deal/getProductDealsInfo'](product.id)
      }
    })
    console.log('getfilted', state.priceFilter.min, state.priceFilter.max)
    if (!isEmpty(state.priceFilter)) {
      console.log('pricefilter', state.priceFilter)
      products = filter(
        products,
        product =>
          product.price >= state.priceFilter.min &&
          product.price <= state.priceFilter.max
      )
    }

    forEach(state.filters, f => {
      if (f.values.length) {
        products = filter(products, product => {
          switch (f.operator) {
            case '=':
              // console.log(
              //   'product',
              //   f.key,
              //   f.values,
              //   f.values.indexOf(product[f.key])
              // );
              return f.values.indexOf(product[f.key]) !== -1
            case '<':
              // console.log('<', f.key, product[f.key], f.values);
              return f.values.some(el => product[f.key] < el)
            case '>':
              // console.log('>', f.key, product[f.key], f.values);
              return f.values.some(el => product[f.key] > el)
          }
        })
      }
    })

    return products

    // return state.filters.length ? filteredProducts : products;
  }
}

export default {
  firestorePath: 'journeys/published/content',
  firestoreRefType: 'collection',
  moduleName: 'journeys',
  statePropName: 'journeys',
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
