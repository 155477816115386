const state = {
  challenges: []
}

const mutations = {}

const actions = {}

const getters = {
  getChallengesForJourney(state) {
    return challengeIds => {
      if (!challengeIds) {
        return {}
      }

      return challengeIds.reduce(function(obj, key) {
        if (Object.prototype.hasOwnProperty.call(state.challenges, key)) {
          obj[key] = state.challenges[key]
        }
        return obj
      }, {})
    }
  },
  getActiveChallengesForJourney: (
    state,
    getters,
    rootState,
    rootGetters
  ) => challengeIds => {
    const _user = rootGetters['user/getUser']
    const challenges = getters.getChallengesForJourney(challengeIds)
    return Object.values(challenges).filter(
      challenge =>
        !_user.completedChallenges.includes(challenge.id) &&
        _user.activeChallenges.includes(challenge.id)
    )
  },
  getNotStartedChallengesForJourney: (
    state,
    getters,
    rootState,
    rootGetters
  ) => challengeIds => {
    const _user = rootGetters['user/getUser']
    const challenges = getters.getChallengesForJourney(challengeIds)

    return Object.values(challenges).filter(
      challenge =>
        !_user.completedChallenges.includes(challenge.id) &&
        !_user.activeChallenges.includes(challenge.id)
    )
  },
  getCompleteChallengesForJourney: (
    state,
    getters,
    rootState,
    rootGetters
  ) => challengeIds => {
    const _user = rootGetters['user/getUser']
    const challenges = getters.getChallengesForJourney(challengeIds)

    return Object.values(challenges).filter(challenge =>
      _user.completedChallenges.includes(challenge.id)
    )
  }
}

export default {
  firestorePath: 'challenges',
  firestoreRefType: 'collection',
  moduleName: 'challenges',
  statePropName: 'challenges',
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
