const state = {
  ideas: []
}

const mutations = {}

const actions = {}

const getters = {}

export default {
  firestorePath: 'ideas',
  firestoreRefType: 'collection',
  moduleName: 'ideas',
  statePropName: 'ideas',
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
