// import { firebase, admin, db, googleAuthProvider } from '../../firebase';
// import router from '../../router';
import Vue from 'vue'
import { storage } from '../../firebase'
import _ from 'lodash'

const state = {
  level: {},
  filters: {},
  orderBy: [
    {
      key: 'order',
      order: 'desc'
    }
  ]
}

const mutations = {
  setOrder(state, value) {
    Vue.set(state, 'orderBy', [value])
  },
  setPriceFilter(state, values) {
    Vue.set(state.priceFilter, 'min', values[0])
    Vue.set(state.priceFilter, 'max', values[1])
  },
  toggleFilter(state, filter) {
    let currentFilter = state.filters[filter.key]

    if (!currentFilter) {
      currentFilter = {
        key: filter.key,
        operator: filter.operator,
        values: []
      }
    }

    if (currentFilter.values.indexOf(filter.value) === -1) {
      currentFilter.values.push(filter.value)
    } else {
      currentFilter.values.splice(currentFilter.values.indexOf(filter.value), 1)
    }

    Vue.set(state.filters, filter.key, currentFilter)
  },
  removeFilter(state, filter) {
    let currentFilter = state.filters[filter.key]

    if (!currentFilter) {
      currentFilter = {
        key: filter.key,
        operator: filter.operator,
        values: []
      }
    }
    currentFilter.values.splice(currentFilter.values.indexOf(filter.value), 1)

    Vue.set(state.filters, filter.key, currentFilter)
  },
  resetFilters(state) {
    state.filters = {}
    state.priceFilter = {}
    console.log('RESET')
  }
}

const actions = {
  async addLevel({ dispatch }, level) {
    const id = await dispatch('insert', {
      name: level.name,
      levelLabel: level.levelLabel,
      nextLevelId: level.nextLevelId,
      order: level.order,
      points: level.points
    })

    if (level.imagePath.constructor.name === 'File') {
      dispatch('addImage', { id, image: level.imagePath })
    } else {
      dispatch('patch', {
        id,
        imagePath: level.imagePath
      })
    }

    if (level.headerImagePath?.constructor?.name === 'File') {
      dispatch('addHeaderImage', {
        id: level.id,
        image: level.headerImagePath
      })
    } else {
      dispatch('patch', {
        id,
        headerImagePath: level.headerImagePath
      })
    }
  },
  addImage({ dispatch }, { id, image }) {
    const storageRef = storage.ref(`level/${id}.jpg`).put(image)
    storageRef.on(
      'state_changed',
      snapshot => {
        console.log('stage_changed')
        this.uploadValue =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      },
      error => {
        console.log('error', error)
      },
      () => {
        this.uploadValue = 100
        storageRef.snapshot.ref.getDownloadURL().then(image => {
          dispatch('set', { id, imagePath: image })
        })
      }
    )
  },
  addHeaderImage({ dispatch }, { id, image }) {
    const storageRef = storage.ref(`level/${id}-header.jpg`).put(image)
    storageRef.on(
      'state_changed',
      snapshot => {
        console.log('stage_changed')
        this.uploadValue =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      },
      error => {
        console.log('error', error)
      },
      () => {
        this.uploadValue = 100
        storageRef.snapshot.ref.getDownloadURL().then(image => {
          dispatch('set', { id, headerImagePath: image })
        })
      }
    )
  },
  async editLevel({ dispatch }, level) {
    console.log('image', level.imagePath.constructor.name, level.imagePath)
    dispatch('patch', {
      id: level.id,
      name: level.name,
      levelLabel: level.levelLabel,
      nextLevelId: level.nextLevelId,
      order: level.order,
      points: level.points
    })

    if (level.imagePath.constructor.name === 'File') {
      dispatch('addImage', { id: level.id, image: level.imagePath })
    }
    if (level?.headerImagePath?.constructor?.name === 'File') {
      dispatch('addHeaderImage', {
        id: level.id,
        image: level.headerImagePath
      })
    }
  },
  deleteProduct({ dispatch }, productId) {
    console.log('delete', productId)
    dispatch('delete', productId)
  },
  toggleLive({ dispatch, state }, productId) {
    dispatch('patch', {
      id: productId,
      live: !state.products[productId].live
    })
  },
  setOrder({ commit }, value) {
    console.log('SET ORDER', value)
    commit('setOrder', value)
  },
  setPriceFilter({ commit }, values) {
    console.log('SET PRICE', values)
    commit('setPriceFilter', values)
  },
  toggleFilter({ commit }, filter) {
    commit('toggleFilter', filter)
  },
  resetFilters({ commit }) {
    commit('resetFilters')
  }
}

const getters = {
  getLevel(state) {
    const _level = _.cloneDeep(state.level)
    // for (const journeyId in _journeys) {
    //   if (Object.hasOwnProperty.call(_journeys, journeyId)) {
    //     const _journey = _journeys[journeyId]
    // TODO: Need to do something with importing images
    //     _journey.image = _journey.imagePath
    //   }
    // }
    return _level
  }
}

export default {
  firestorePath: 'journeys/published/content/{journeyId}/levels/{levelId}',
  firestoreRefType: 'doc',
  moduleName: 'level',
  statePropName: 'level',
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
