<template>
  <div class="flex-item" :class="classes" :style="styles">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: [String, Number],
      default: null
    },
    grow: {
      type: Boolean,
      default: false
    },
    shrink: {
      type: Boolean,
      default: true
    },
    basis: {
      type: String,
      default: null
    },
    alignSelf: {
      type: String,
      default: null
    },
    textAlign: {
      type: String,
      default: null
    },
    padding: {
      type: String,
      default: null
    },
    paddingHorizontal: {
      type: String,
      default: null
    }
  },
  computed: {
    classes: function() {
      return {
        'flex-item--grow': this.grow,
        'flex-item--shrink': this.shrink,
        'flex-item__align-self--flex-end': this.alignSelf === 'flex-end',
        'flex-item__align-self--center': this.alignSelf === 'center',
        'flex-item__align-self--stretch': this.alignSelf === 'stretch',
        'flex-item__text-align--center': this.textAlign === 'center',
        'flex-item__padding--small': this.padding === 'small',
        'flex-item__padding': this.padding === 'normal',
        'flex-item__padding--large':
          this.padding === 'large' || this.paddingHorizontal === 'large',
        'flex-item__padding--small-horizontal':
          this.paddingHorizontal === 'small',
        'flex-item__padding--horizontal': this.paddingHorizontal === 'normal'
      }
    },
    styles: function() {
      return {
        width: this.size,
        'flex-basis': this.basis
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.flex-item {
  flex: 0 0 auto;
  text-align: left;

  &--grow {
    flex-grow: 1;
  }

  &--shrink {
    flex-shrink: 1;
  }

  &__align-self--flex-end {
    align-self: flex-end;
  }

  &__align-self--center {
    align-self: center;
  }

  &__align-self--stretch {
    align-self: stretch;
  }

  &__text-align--center {
    text-align: center;
  }

  @include respond-below(sm) {
    width: 100% !important;
  }
}
</style>
