<template>
  <router-link
    :to="to"
    class="button"
    :class="classes"
    :target="target"
    :disabled="disabled"
    @click="onClick"
  >
    <i v-if="icon" :class="`fal fa-${icon}`" /><span
      :class="icon ? 'u__margin-left' : ''"
      ><slot>ButtonLink</slot></span
    >
  </router-link>
</template>

<script>
import Button from '@/components/global/ui/Button.vue'
export default {
  extends: Button,
  props: {
    to: {
      type: String,
      default: null
    },
    target: {
      type: String,
      default: null
    }
  }
}
</script>
