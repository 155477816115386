<template>
  <figure ref="imageContainer" class="figure" :class="classes" :style="styles">
    <img v-if="src" :src="src" />
    <div v-else class="item__no-image">
      <i :class="`fal fa-${icon}`" :style="fontSize" />
    </div>
  </figure>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: 'image'
    },
    width: {
      type: [String, Number],
      default: null
    },
    height: {
      type: [String, Number],
      default: null
    },
    align: {
      type: String,
      default: null
    },
    margin: {
      type: String,
      default: null
    }
  },
  data: () => {
    return {
      fontSize: '12px'
    }
  },
  computed: {
    classes: function() {
      return {
        'figure--align-center': this.align === 'center',
        'figure--align-right': this.align === 'right'
      }
    },
    styles: function() {
      if (this.width && this.height && this.margin) {
        return {
          width: `${this.width}`,
          height: `${this.height}`,
          margin: `${this.margin}`
        }
      }
      if (this.width) {
        return {
          width: `${this.width}`,
          height: 'auto'
        }
      }
      if (this.height) {
        return {
          maxWidth: 'auto',
          height: `${this.height}`
        }
      }
      if (this.margin) {
        return {
          margin: `${this.margin}`
        }
      }
      return {}
    }
  },
  mounted() {
    let size = this.$refs.imageContainer.clientWidth
    if (
      this.$refs.imageContainer.clientHeight <
      this.$refs.imageContainer.clientWidth
    ) {
      size = this.$refs.imageContainer.clientHeight
    }

    this.fontSize = {
      fontSize: `${size * 0.8}px`
    }
  }
}
</script>

<style lang="scss" scoped>
.figure {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: calc(100% + 40px);
  margin: auto -20px;

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
  }

  &--align-center {
    margin-left: auto;
    margin-right: auto;
  }

  &--align-right {
    margin-left: auto;
    margin-right: 0;
  }

  // @include respond-below(sm) {
  //   width: 100% !important;
  // }
}

// img {
//   width: 100%;
//   // height: 90px;
//   border-radius: $border-radius;
// }

.item__no-image {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-white;
  font-size: 3em;
  background-size: cover;
  background-image: url('~@/assets/overlay4.svg'),
    linear-gradient(
      45deg,
      rgb(206, 206, 206),
      #e7e7e7 50%,
      rgb(238, 238, 238) 95%
    );
  background-position: top left, center center, center center;
}

//   font-size: 2rem;
//   line-height: 2rem;
//   color: $color-white;
//   width: 100%;
//   border-radius: $border-radius;
</style>
