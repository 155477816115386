<template>
  <div class="file-input">
    <div class="file-input__image">
      <ImageItem :src="getImage" width="160px" height="90px" />
    </div>
    <div class="file-input__input">
      <input
        type="file"
        class="input__element"
        :disabled="disabled"
        @change="onChange"
        @input="$emit('input', $event.target.files[0])"
      />
      <i v-if="false && value && !hasError" class="fal fa-check-circle" />
      <i v-if="false && hasError" class="fal fa-times-circle" />
      <p v-if="hasError" class="error">
        {{ errorMsg }}
      </p>
      <div v-show="false">
        <p>
          Progress: {{ uploadValue.toFixed() + '%' }}
          <progress id="progress" :value="uploadValue" max="100" />
        </p>
      </div>
      <div v-if="false">
        <img class="preview" :src="picture" />
        <br />
        <Button type="secondary" :on-click="onUpload">
          Upload
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { storage } from '@/firebase'
import TextInput from '@/components/global/form/TextInput'
export default {
  extends: TextInput,
  props: {
    path: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      imageData: null,
      picture: null,
      uploadValue: 0
    }
  },
  computed: {
    getImage() {
      return this.imageData || this.value
    }
  },
  methods: {
    onChange($event) {
      this.previewImage($event)
      this.$emit('change', $event.target.files[0])
    },
    previewImage(event) {
      this.uploadValue = 0
      this.picture = null
      this.imageData = URL.createObjectURL(event.target.files[0])
    },
    onUpload() {
      this.picture = null
      const storageRef = storage.ref(this.path).put(this.imageData)
      storageRef.on(
        'state_changed',
        snapshot => {
          console.log('stage_changed')
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        },
        error => {
          console.log('error', error)
        },
        () => {
          this.uploadValue = 100
          storageRef.snapshot.ref.getDownloadURL().then(url => {
            this.picture = url
          })
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.file-input__image {
  margin-bottom: $margin;
}
.input__element {
  display: block;
  background: rgba(255, 255, 255, 0.4);
  width: 100%;
  line-height: 4.8rem;
  font-size: 1.6rem;
  color: $color-gray--darker;

  &:disabled {
    color: $color-gray;
  }
}
</style>
