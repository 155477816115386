<template>
  <div class="breadcrumbs">
    <Content>
      <ul class="breadcrumbs__list" :class="classes">
        <li>
          <IconLink icon="home" to="/" />
        </li>
        <li v-for="(item, index) in items" :key="index">
          <Icon icon="chevron-circle-right" />
          <span v-if="item.to">
            <router-link :to="item.to">{{ item.text }}</router-link>
          </span>
          <span v-else>{{ item.text }}</span>
        </li>
      </ul>
    </Content>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      validator: function(value) {
        return (
          ['primary', 'secondary', 'tertiary', 'white'].indexOf(value) !== -1
        )
      },
      default: 'primary'
    }
  },
  computed: {
    classes: function() {
      return {
        'breadcrumbs__list--primary': this.type === 'primary',
        'breadcrumbs__list--secondary': this.type === 'secondary',
        'breadcrumbs__list--tertiary': this.type === 'tertiary',
        'breadcrumbs__list--white': this.type === 'white'
      }
    }
  }
}
</script>

<style lang="scss">
.breadcrumbs {
  background: $color-primary;
  padding: $padding-small 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);

  @include respond-below(sm) {
    padding: 0;
  }
}

.breadcrumbs__list {
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
  color: $color-black;

  li {
    font-size: 1.6rem;
    display: inline-block;
    vertical-align: middle;
    padding-right: $padding-small;

    &:last-of-type {
      padding-right: 0;
    }

    .icon i,
    .icon-link a {
      color: inherit;
      font-size: inherit;
    }

    span a {
      color: inherit;
      border: none;
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }
    }

    @include respond-below(sm) {
      font-size: 1.4rem;
    }
  }

  span {
    padding-left: $padding-small;
  }

  &--primary {
    color: $color-primary;

    &:before,
    &:after {
      border-bottom: 1px solid $color-primary;
    }
  }

  &--secondary {
    color: $color-secondary;

    &:before,
    &:after {
      border-bottom: 1px solid $color-secondary;
    }
  }

  &--tertiary {
    color: $color-tertiary;

    &:before,
    &:after {
      border-bottom: 1px solid $color-tertiary;
    }
  }

  &--white {
    color: $color-white;

    &:before,
    &:after {
      border-bottom: 1px solid $color-white;
    }
  }

  &__text {
    font-weight: 300;
    line-height: 2.2rem;
    letter-spacing: 0.05em;
    text-decoration: none;
    text-transform: uppercase;
    padding: 0 1rem;
  }
}
</style>
