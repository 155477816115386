import Vue from 'vue'
import Vuelidate from 'vuelidate'
import App from './App.vue'
import router from './router'
import store from './store'
import VueScrollTo from 'vue-scrollto'
import VueAnalytics from 'vue-analytics'
import VueProgressBar from 'vue-progressbar'
import VueSlider from 'vue-slider-component'

import ButtonSubmit from '@/components/global/form/ButtonSubmit'
import CheckboxInput from '@/components/global/form/CheckboxInput'
import EmailInput from '@/components/global/form/EmailInput'
import Field from '@/components/global/form/Field'
import FieldGroup from '@/components/global/form/FieldGroup'
import PasswordInput from '@/components/global/form/PasswordInput'
import SelectInput from '@/components/global/form/SelectInput'
import TextInput from '@/components/global/form/TextInput'
import TextAreaInput from '@/components/global/form/TextAreaInput'
import FileInput from '@/components/global/form/FileInput'
import AutoCompleteInput from '@/components/global/form/AutoCompleteInput'
import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'swiper/swiper-bundle.css'
import 'swiper/css/swiper.css'

import Alert from '@/components/global/ui/Alert'
import Button from '@/components/global/ui/Button'
import ButtonLink from '@/components/global/ui/ButtonLink'
import ButtonRouterLink from '@/components/global/ui/ButtonRouterLink'
import ButtonScroll from '@/components/global/ui/ButtonScroll'
import ButtonIcon from '@/components/global/ui/ButtonIcon'
import Pill from '@/components/global/ui/Pill'
import Content from '@/components/global/ui/Content'
import Breadcrumbs from '@/components/global/ui/Breadcrumbs'
import CopyWithImage from '@/components/global/ui/CopyWithImage'
import Divider from '@/components/global/ui/Divider'
import FlexContainer from '@/components/global/ui/FlexContainer'
import FlexItem from '@/components/global/ui/FlexItem'
import Icon from '@/components/global/ui/Icon'
import IconLink from '@/components/global/ui/IconLink'
import Card from '@/components/global/ui/Card'
import PrimaryCard from '@/components/global/ui/cards/PrimaryCard'
import ImageItem from '@/components/global/ui/ImageItem'
import TabNavigation from '@/components/global/ui/TabNavigation'
import Modal from '@/components/global/ui/Modal'
import PriceFilter from '@/components/global/ui/filters/PriceFilter'
import CheckboxFilter from '@/components/global/ui/filters/CheckboxFilter'
import { Datetime } from 'vue-datetime'
// You need a specific loader for CSS files
import 'vue-datetime/dist/vue-datetime.css'
import VueLuxon from 'vue-luxon'

const progressBarOptions = {
  color: '#5ac6cb',
  failedColor: '#f5594b',
  thickness: '5px',
  transition: {
    speed: '0.6s',
    opacity: '0.9s',
    termination: 700
  },
  autoRevert: true,
  location: 'top',
  inverse: false
}

const breakpoints = {
  // default breakpoints - customize this
  sm: 768,
  md: Infinity,
  lg: Infinity
}

import VueMq from 'vue-mq'

Vue.use(VueMq, {
  breakpoints,
  defaultBreakpoint: 'md' // customize this for SSR
})

Vue.use(VueProgressBar, progressBarOptions)
Vue.use(VueAwesomeSwiper)
Vue.config.productionTip = false
Vue.use(VueScrollTo)
Vue.use(Vuelidate)
Vue.use(VueAnalytics, {
  id: 'UA-135635693-1'
})
Vue.use(Datetime)
Vue.use(VueLuxon)
Vue.component('ButtonSubmit', ButtonSubmit)
Vue.component('CheckboxInput', CheckboxInput)
Vue.component('EmailInput', EmailInput)
Vue.component('Field', Field)
Vue.component('FieldGroup', FieldGroup)
Vue.component('PasswordInput', PasswordInput)
Vue.component('SelectInput', SelectInput)
Vue.component('TextInput', TextInput)
Vue.component('TextAreaInput', TextAreaInput)
Vue.component('FileInput', FileInput)
Vue.component('AutoCompleteInput', AutoCompleteInput)
Vue.component('SliderInput', VueSlider)

Vue.component('Alert', Alert)
Vue.component('Button', Button)
Vue.component('ButtonLink', ButtonLink)
Vue.component('ButtonRouterLink', ButtonRouterLink)
Vue.component('ButtonScroll', ButtonScroll)
Vue.component('ButtonIcon', ButtonIcon)
Vue.component('Pill', Pill)
Vue.component('Content', Content)
Vue.component('Breadcrumbs', Breadcrumbs)
Vue.component('CopyWithImage', CopyWithImage)
Vue.component('Divider', Divider)
Vue.component('FlexContainer', FlexContainer)
Vue.component('FlexItem', FlexItem)
Vue.component('Icon', Icon)
Vue.component('IconLink', IconLink)
Vue.component('Card', Card)
Vue.component('PrimaryCard', PrimaryCard)
Vue.component('ImageItem', ImageItem)
Vue.component('TabNavigation', TabNavigation)
Vue.component('Modal', Modal)
Vue.component('PriceFilter', PriceFilter)
Vue.component('CheckboxFilter', CheckboxFilter)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
