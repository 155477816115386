<template>
  <a
    :href="href"
    class="button"
    :class="classes"
    :target="target"
    :disabled="disabled"
    @click="onClick"
  >
    <i v-if="icon" :class="`fal fa-${icon}`" /><span
      :class="icon ? 'u__margin-left' : ''"
      ><slot>ButtonLink</slot></span
    >
  </a>
</template>

<script>
import Button from '@/components/global/ui/Button.vue'
export default {
  extends: Button,
  props: {
    href: {
      type: String,
      default: null
    },
    target: {
      type: String,
      default: null
    }
  }
}
</script>
