// import { firebase, admin, db, googleAuthProvider } from '../../firebase';
// import router from '../../router';
import Vue from 'vue'
import { storage } from '../../firebase'
import _ from 'lodash'

const state = {
  journey: {},
  filters: {},
  orderBy: [
    {
      key: 'order',
      order: 'desc'
    }
  ]
}

const mutations = {
  setOrder(state, value) {
    Vue.set(state, 'orderBy', [value])
  },
  setPriceFilter(state, values) {
    Vue.set(state.priceFilter, 'min', values[0])
    Vue.set(state.priceFilter, 'max', values[1])
  },
  toggleFilter(state, filter) {
    let currentFilter = state.filters[filter.key]

    if (!currentFilter) {
      currentFilter = {
        key: filter.key,
        operator: filter.operator,
        values: []
      }
    }

    if (currentFilter.values.indexOf(filter.value) === -1) {
      currentFilter.values.push(filter.value)
    } else {
      currentFilter.values.splice(currentFilter.values.indexOf(filter.value), 1)
    }

    Vue.set(state.filters, filter.key, currentFilter)
  },
  removeFilter(state, filter) {
    let currentFilter = state.filters[filter.key]

    if (!currentFilter) {
      currentFilter = {
        key: filter.key,
        operator: filter.operator,
        values: []
      }
    }
    currentFilter.values.splice(currentFilter.values.indexOf(filter.value), 1)

    Vue.set(state.filters, filter.key, currentFilter)
  },
  resetFilters(state) {
    state.filters = {}
    state.priceFilter = {}
    console.log('RESET')
  }
}

const actions = {
  async addJourney({ dispatch }, journey) {
    console.log('addJourney')
    const id = await dispatch('insert', {
      name: journey.name,
      subtitle: journey.subtitle,
      description: journey.description,
      savings: journey.savings,
      taxRelief: journey.taxRelief
    })

    if (journey.imagePath.constructor.name === 'File') {
      dispatch('addImage', { id, image: journey.imagePath })
    } else {
      dispatch('patch', {
        id,
        imagePath: journey.imagePath
      })
    }
    console.log('HEADER', journey.headerImagePath)
    if (journey.headerImagePath.constructor.name === 'File') {
      dispatch('addHeaderImage', {
        id: journey.id,
        image: journey.headerImagePath
      })
    } else {
      dispatch('patch', {
        id,
        headerImagePath: journey.headerImagePath
      })
    }
  },
  addImage({ dispatch }, { id, image }) {
    console.log('addImage', image)
    const storageRef = storage.ref(`journey/${id}.jpg`).put(image)
    storageRef.on(
      'state_changed',
      snapshot => {
        console.log('stage_changed')
        this.uploadValue =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      },
      error => {
        console.log('error', error)
      },
      () => {
        this.uploadValue = 100
        console.log('upload100', image)
        storageRef.snapshot.ref.getDownloadURL().then(image => {
          dispatch('set', { id, imagePath: image })
        })
      }
    )
  },
  addHeaderImage({ dispatch }, { id, image }) {
    const storageRef = storage.ref(`level/${id}-header.jpg`).put(image)
    storageRef.on(
      'state_changed',
      snapshot => {
        console.log('stage_changed')
        this.uploadValue =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      },
      error => {
        console.log('error', error)
      },
      () => {
        this.uploadValue = 100
        storageRef.snapshot.ref.getDownloadURL().then(image => {
          console.log('set', id, image)
          dispatch('set', { id, headerImagePath: image })
        })
      }
    )
  },
  async editJourney({ dispatch }, journey) {
    console.log(
      'editJourney',
      journey.imagePath.constructor.name,
      journey.imagePath
    )
    dispatch('patch', {
      id: journey.id,
      name: journey.name,
      subtitle: journey.subtitle,
      description: journey.description,
      savings: journey.savings,
      taxRelief: journey.taxRelief
    })

    if (journey.imagePath.constructor.name === 'File') {
      dispatch('addImage', { id: journey.id, image: journey.imagePath })
    }

    console.log('HEADER', journey.headerImagePath)
    if (journey.headerImagePath.constructor.name === 'File') {
      dispatch('addHeaderImage', {
        id: journey.id,
        image: journey.headerImagePath
      })
    }
  },
  deleteProduct({ dispatch }, productId) {
    console.log('delete', productId)
    dispatch('delete', productId)
  },
  toggleLive({ dispatch, state }, productId) {
    dispatch('patch', {
      id: productId,
      live: !state.products[productId].live
    })
  },
  setOrder({ commit }, value) {
    console.log('SET ORDER', value)
    commit('setOrder', value)
  },
  setPriceFilter({ commit }, values) {
    console.log('SET PRICE', values)
    commit('setPriceFilter', values)
  },
  toggleFilter({ commit }, filter) {
    commit('toggleFilter', filter)
  },
  resetFilters({ commit }) {
    commit('resetFilters')
  }
}

const getters = {
  getJourney(state) {
    return _.cloneDeep(state.journey)
  },
  getPointsAvailable(state, getters, rootState, rootGetters) {
    return rootGetters['levels/getLevelsAsArray']
      .map(level => level.points)
      .reduce((total, nextPoints) => total + nextPoints, 0)
  }
}

export default {
  firestorePath: 'journeys/published/content/{journeyId}',
  firestoreRefType: 'doc',
  moduleName: 'journey',
  statePropName: 'journey',
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
