<template>
  <Card2 :shadow="true">
    <div
      class="media"
      :style="{ 'background-image': 'url(' + imageSrc + ')' }"
    />
    <div
      class="body-wrapper"
      :style="{
        'background-color': backgroundColor,
        color: textColor
      }"
    >
      <div class="body">
        <slot name="heading" />
        <slot name="content" />
        <slot name="actions" />
      </div>
    </div>
  </Card2>
</template>

<script>
import Card2 from '@/components/global/ui/Card2.vue'
export default {
  name: 'PrimaryCard',
  components: {
    Card2
  },
  props: {
    imageSrc: {
      type: String,
      default: ''
    },
    backgroundColor: {
      type: String,
      default: ''
    },
    textColor: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 16px;

  @include respond-above(md) {
    display: flex;
    align-items: stretch;
  }
}

.media {
  text-align: center;
  background-position: center center;
  height: 156px;

  @include respond-above(md) {
    width: 35%;
    order: 2;
    height: auto;
  }
}

.heading {
  font-size: 3.6rem;
  line-height: 4.2rem;
  font-weight: 400;

  .sub-heading {
    line-height: 1.8rem;
    display: block;
    font-size: 1.4rem;
    font-weight: 600;
  }
}

.content {
  font-size: 2rem;
  line-height: 1.9rem;
  font-weight: 400;
  align-self: center;
}

.body-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include respond-above(md) {
    width: 65%;
    flex: 1;
  }
}

.body {
  align-items: center;
  padding: 16px 23px 21px;

  @include respond-above(md) {
    padding: 14px 30px 22px;
  }
}

.actions {
  .button {
    border: 1px solid white;
    color: white;
  }
}
</style>
