<template>
  <div class="field-group">
    <label v-if="label" class="field-group__label">{{ label }}</label>
    <div class="field-group__container">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FieldGroup',
  props: {
    label: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
.field-group {
  margin-bottom: $margin-large;

  &__label {
    font-weight: 400;
    font-size: 2.4rem;
  }

  &__container {
    width: 100%;
    display: flex;

    @include respond-below(sm) {
      display: block;
      margin-bottom: $margin;
    }
  }

  .field {
    width: 50%;
    margin: 0 $margin;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }

    @include respond-below(sm) {
      flex: 1 1 auto;
      margin: 0;
      margin-bottom: $margin;
      width: 100%;
    }
  }
}
</style>
