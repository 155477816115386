<template>
  <span class="pill" :class="classes">
    <slot>Pill</slot>
  </span>
</template>

<script>
export default {
  name: 'Pill',
  props: {
    type: {
      type: String,
      validator: function(value) {
        return (
          ['primary', 'secondary', 'tertiary', 'quaternary'].indexOf(value) !==
          -1
        )
      },
      default: 'primary'
    }
  },
  computed: {
    classes: function() {
      return {
        'pill--primary': this.type === 'primary',
        'pill--secondary': this.type === 'secondary',
        'pill--tertiary': this.type === 'tertiary',
        'pill--quaternary': this.type === 'quaternary'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.pill {
  display: inline-block;
  position: relative;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 1.4rem;
  height: 3.2rem;
  border-radius: 3.2rem;
  padding: 0.7rem 1.2rem;
  background-color: transparent;
  color: #fff;
  vertical-align: middle;

  &--primary {
    background-color: $color-primary;
  }

  &--secondary {
    background-color: $color-secondary;
  }

  &--tertiary {
    background-color: $color-tertiary;
    color: $color-black;
  }

  &--quaternary {
    background-color: #f7f8f3;
    color: $color-black;
  }
}
</style>
