<template>
  <button class="button" :class="classes" @click="handleClick">
    <i v-if="icon" :class="`far fa-${icon}`" />
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    type: {
      type: String,
      validator: function(value) {
        return (
          ['primary', 'secondary', 'tertiary', 'success', 'error'].indexOf(
            value
          ) !== -1
        )
      },
      default: 'primary'
    },
    icon: {
      type: String,
      default: null
    },
    onClick: {
      type: Function,
      default: () => {}
    },
    disabled: {
      type: Boolean
    },
    fullWidth: {
      type: [Boolean, String],
      default: false
    },
    size: {
      type: String,
      validator: function(value) {
        return ['small', 'medium', 'large'].indexOf(value) !== -1
      },
      default: 'medium'
    }
  },
  computed: {
    classes: function() {
      return {
        'button--primary': this.type === 'primary',
        'button--secondary': this.type === 'secondary',
        'button--tertiary': this.type === 'tertiary',
        'button--success': this.type === 'success',
        'button--full-width': this.fullWidth,
        'button--small': this.size === 'small',
        'button--large': this.size === 'large'
      }
    }
  },
  methods: {
    handleClick(event) {
      this.$emit('onClick', event)
      this.onClick()
    }
  }
}
</script>

<style lang="scss" scoped>
.button {
  appearance: none;
  display: inline-block;
  position: relative;
  font-weight: 700;
  letter-spacing: 0.125em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 1.2rem;
  height: 4.8rem;
  line-height: 4.8rem;
  border-radius: 4.8rem;
  background-color: transparent;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.7);
  color: $color-white;
  transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
    color 0.2s ease-in-out;

  .far {
    font-size: 2rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.125);
  }

  &--small {
    font-size: 1.4rem;
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 2.8rem;

    .far {
      font-size: 1.4rem;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &--large {
    font-size: 1.8rem;
    height: 4.8rem;
    line-height: 4.8rem;
    border-radius: 4.8rem;
    padding: 0 3rem;
  }

  &--primary {
    background-color: $color-primary;
    border: none;
    color: #fff;
    box-shadow: none;

    &:hover {
      background-color: $color-primary--dark;
    }
  }

  &--secondary {
    background-color: $color-secondary;
    border: none;
    color: #fff;
    box-shadow: none;

    &:hover {
      background-color: $color-secondary--dark;
    }
  }

  &--tertiary {
    background-color: $color-tertiary;
    border: none;
    color: #fff;
    box-shadow: none;

    &:hover {
      background-color: $color-tertiary--dark;
    }
  }

  &--success {
    background-color: $color-success;
    border: none;
    color: #fff;
    box-shadow: none;

    &:hover {
      background-color: $color-tertiary--dark;
    }
  }

  &:disabled,
  &[disabled='disabled'] {
    background-color: $color-gray;
    opacity: 0.75;
  }

  &--full-width {
    width: 100%;
  }

  // @include respond-below(sm) {
  //   width: 100%;
  //   max-width: 100%;
  // }
}
</style>
