// import { firebase, admin, db, googleAuthProvider } from '../../firebase';
import Vue from 'vue'
import { storage } from '../../firebase'
import _, { filter, forEach, isEmpty } from 'lodash'

const state = {
  levels: {},
  filters: {},
  orderBy: [
    {
      key: 'order',
      order: 'desc'
    }
  ]
}

const mutations = {
  setOrder(state, value) {
    Vue.set(state, 'orderBy', [value])
  },
  setPriceFilter(state, values) {
    Vue.set(state.priceFilter, 'min', values[0])
    Vue.set(state.priceFilter, 'max', values[1])
  },
  toggleFilter(state, filter) {
    let currentFilter = state.filters[filter.key]

    if (!currentFilter) {
      currentFilter = {
        key: filter.key,
        operator: filter.operator,
        values: []
      }
    }

    if (currentFilter.values.indexOf(filter.value) === -1) {
      currentFilter.values.push(filter.value)
    } else {
      currentFilter.values.splice(currentFilter.values.indexOf(filter.value), 1)
    }

    Vue.set(state.filters, filter.key, currentFilter)
  },
  removeFilter(state, filter) {
    let currentFilter = state.filters[filter.key]

    if (!currentFilter) {
      currentFilter = {
        key: filter.key,
        operator: filter.operator,
        values: []
      }
    }
    currentFilter.values.splice(currentFilter.values.indexOf(filter.value), 1)

    Vue.set(state.filters, filter.key, currentFilter)
  },
  resetFilters(state) {
    state.filters = {}
    state.priceFilter = {}
    console.log('RESET')
  }
}

const actions = {
  async addLevel({ dispatch }, level) {
    const id = await dispatch('insert', {
      name: level.name,
      levelLabel: level.levelLabel,
      nextLevelId: level.nextLevelId,
      order: level.order,
      points: level.points
    })

    if (level.imagePath.constructor.name === 'File') {
      dispatch('addImage', { id, image: level.imagePath })
    } else {
      dispatch('patch', {
        id,
        imagePath: level.imagePath
      })
    }

    if (level.headerImagePath.constructor.name === 'File') {
      dispatch('addHeaderImage', {
        id,
        image: level.headerImagePath
      })
    } else {
      dispatch('patch', {
        id,
        headerImagePath: level.headerImagePath
      })
    }
  },
  addImage({ dispatch }, { id, image }) {
    const storageRef = storage.ref(`level/${id}.jpg`).put(image)
    storageRef.on(
      'state_changed',
      snapshot => {
        console.log('stage_changed')
        this.uploadValue =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      },
      error => {
        console.log('error', error)
      },
      () => {
        this.uploadValue = 100
        storageRef.snapshot.ref.getDownloadURL().then(image => {
          dispatch('set', { id, imagePath: image })
        })
      }
    )
  },
  addHeaderImage({ dispatch }, { id, image }) {
    const storageRef = storage.ref(`level/${id}-header.jpg`).put(image)
    storageRef.on(
      'state_changed',
      snapshot => {
        console.log('stage_changed')
        this.uploadValue =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      },
      error => {
        console.log('error', error)
      },
      () => {
        this.uploadValue = 100
        storageRef.snapshot.ref.getDownloadURL().then(image => {
          dispatch('set', { id, headerImagePath: image })
        })
      }
    )
  },
  async editLevel({ dispatch }, level) {
    console.log('image', level.imagePath.constructor.name, level.imagePath)
    dispatch('patch', {
      id: level.id,
      name: level.name,
      levelLabel: level.levelLabel,
      nextLevelId: level.nextLevelId,
      order: level.order,
      points: level.points
    })

    if (level.imagePath.constructor.name === 'File') {
      dispatch('addImage', { id: level.id, image: level.imagePath })
    }
    if (level.headerImagePath.constructor.name === 'File') {
      dispatch('addHeaderImage', {
        id: level.id,
        image: level.headerImagePath
      })
    }
  },
  deleteProduct({ dispatch }, productId) {
    console.log('delete', productId)
    dispatch('delete', productId)
  },
  toggleLive({ dispatch, state }, productId) {
    dispatch('patch', {
      id: productId,
      live: !state.products[productId].live
    })
  },
  setOrder({ commit }, value) {
    console.log('SET ORDER', value)
    commit('setOrder', value)
  },
  setPriceFilter({ commit }, values) {
    console.log('SET PRICE', values)
    commit('setPriceFilter', values)
  },
  toggleFilter({ commit }, filter) {
    commit('toggleFilter', filter)
  },
  resetFilters({ commit }) {
    commit('resetFilters')
  }
}

const getters = {
  getLevels(state, getters, rootState, rootGetters) {
    const _levels = _.cloneDeep(state.levels)
    for (const levelId in _levels) {
      if (Object.hasOwnProperty.call(_levels, levelId)) {
        const _level = _levels[levelId]
        const _user = rootGetters['user/getUser']

        _.includes(_user.levelsCompleted, _level.id)
          ? (_level.hasUserCompleted = true)
          : (_level.hasUserCompleted = false)
        // TODO: Need to do something with importing images
        // _journey.image = _journey.imagePath
      }
    }
    return _levels
  },
  getLevelsAsArray(state) {
    return Object.keys(state.levels).map(id => state.levels[id])
  },
  getNextLevelIndex(state, getters, rootState) {
    if (!rootState.user.currentUser?.levelsCompleted) {
      return null
    }

    const levelsCompleted = rootState.user.currentUser.levelsCompleted
    const levelsArray = Object.keys(state.levels).map(id => state.levels[id])

    if (levelsArray.length === 0) {
      return null
    }

    const levelIds = Object.keys(state.levels)
    const completedLevels = levelIds.filter(id => levelsCompleted.includes(id))
    const lastLevelId = completedLevels[completedLevels.length - 1]
    return levelsArray.findIndex(level => level.id === lastLevelId) + 1
  },
  getNextLevel(state, getters) {
    const levelsArray = Object.keys(state.levels).map(id => state.levels[id])

    return levelsArray[getters.getNextLevelIndex]
  },
  getNextLevelId(state, getters) {
    return getters.getNextLevel?.id
  },
  isCompleted: (state, getters, rootState) => id => {
    if (!rootState.user.currentUser?.levelsCompleted) {
      return false
    }
    return rootState.user.currentUser.levelsCompleted?.includes(id)
  },
  getFilteredProducts(state, getters, rootState, rootGetters) {
    let products = state.products

    products = filter(products, product => {
      return product.live === true
    })

    products.forEach((product, index) => {
      products[index] = {
        ...product,
        ...rootGetters['deal/getProductDealsInfo'](product.id)
      }
    })
    console.log('getfilted', state.priceFilter.min, state.priceFilter.max)
    if (!isEmpty(state.priceFilter)) {
      console.log('pricefilter', state.priceFilter)
      products = filter(
        products,
        product =>
          product.price >= state.priceFilter.min &&
          product.price <= state.priceFilter.max
      )
    }

    forEach(state.filters, f => {
      if (f.values.length) {
        products = filter(products, product => {
          switch (f.operator) {
            case '=':
              // console.log(
              //   'product',
              //   f.key,
              //   f.values,
              //   f.values.indexOf(product[f.key])
              // );
              return f.values.indexOf(product[f.key]) !== -1
            case '<':
              // console.log('<', f.key, product[f.key], f.values);
              return f.values.some(el => product[f.key] < el)
            case '>':
              // console.log('>', f.key, product[f.key], f.values);
              return f.values.some(el => product[f.key] > el)
          }
        })
      }
    })

    return products

    // return state.filters.length ? filteredProducts : products;
  }
}

export default {
  firestorePath: 'journeys/published/content/{journeyId}/levels',
  firestoreRefType: 'collection',
  moduleName: 'levels',
  statePropName: 'levels',
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
