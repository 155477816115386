<template>
  <label class="input__checkbox-label">
    <input
      type="checkbox"
      class="input__checkbox"
      :checked="shouldBeChecked"
      :value="value"
      :disabled="disabled"
      @change="updateInput"
      @click="$emit('onClick', $event)"
    />
    <span class="input__checkbox-input" :class="error">
      <slot />
    </span>
  </label>
</template>

<script>
export default {
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    disabled: {
      type: Boolean,
      value: false
    },
    value: {
      type: String,
      default: null
    },
    modelValue: {
      type: Boolean,
      default: false
    },
    trueValue: {
      type: Boolean,
      default: true
    },
    falseValue: {
      type: Boolean,
      default: false
    },
    vError: {
      type: Boolean,
      default: function() {
        return false
      }
    }
  },
  computed: {
    shouldBeChecked() {
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.value)
      }

      return this.modelValue === this.trueValue
    },
    error() {
      return {
        'input__checkbox-input--error': this.vError
      }
    }
  },
  methods: {
    updateInput(event) {
      const isChecked = event.target.checked

      if (this.modelValue instanceof Array) {
        const newValue = [...this.modelValue]

        if (isChecked) {
          newValue.push(this.value)
        } else {
          newValue.splice(newValue.indexOf(this.value), 1)
        }

        this.$emit('change', newValue)
      } else {
        this.$emit('change', isChecked ? this.trueValue : this.falseValue)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.input__checkbox-label {
  cursor: pointer;
  display: block;
  padding-left: 0;
  position: relative;
  font-size: 1.4rem;
  color: $color-black;
}

.input__checkbox-input {
  position: relative;
  display: inline-block;
  padding: $padding-small $padding-large;
  font-size: 1.4rem;

  &::before,
  &::after {
    position: absolute;
    content: '';
    display: inline-block;
  }

  &::before {
    height: 24px;
    width: 24px;

    border-radius: $border-radius-small;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);

    border: 2px solid $color-gray;
  }

  &--error {
    color: $color-secondary;

    &::before {
      border: 2px solid $color-secondary;
    }
  }
}

[type='checkbox']:checked + .input__checkbox-input {
  &::before {
    border: 2px solid $color-primary;
    background-color: $color-primary;
  }

  &::after {
    top: 0;
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 4px;
    font-size: 1.6rem;
    color: white;
    content: '\f00c';
    font-family: 'Font Awesome 5 Pro';
  }
}

.input__checkbox {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  width: 1px;
  opacity: 0;
  position: absolute;
}
</style>
