<template>
  <nav class="card-navigation">
    <a
      v-for="(item, index) in items"
      :key="index"
      :class="{ active: active === index }"
      :href="item.href"
      @click="item.onClick(index)"
    >
      {{ item.text }}
    </a>
  </nav>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    active: {
      // Index of <a>
      type: Number,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
.card-navigation {
  position: relative;
  background: $color-white;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: $border-radius $border-radius 0 0;
  z-index: 1;

  a {
    display: inline-block;
    font-size: 2.4rem;
    padding: 2.5rem $padding-large;
    text-decoration: none;
    border-bottom: 0;
    margin-bottom: 4px;

    &:hover {
      margin-bottom: 0;
      border-bottom: 4px solid $color-primary--dark;
      color: $color-primary--dark;
    }
  }

  .active {
    border-bottom: 4px solid $color-primary;
    color: $color-primary;
    font-weight: 300;
    margin-bottom: 0;
  }

  @include respond-below(md) {
    padding: 0 $padding;
    margin-bottom: 0;

    a {
      font-size: 1.6rem;
      padding: $padding;
    }
  }
}
</style>
