<template>
  <div class="flex-container" :class="classes">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    wrap: {
      type: Boolean
    },
    padding: {
      type: String,
      default: null
    },
    paddingHorizontal: {
      type: String,
      default: null
    },
    paddingVertical: {
      type: String,
      default: null
    },
    forcePadding: {
      type: Boolean,
      default: false
    },
    flexDirection: {
      type: String,
      default: null
    },
    justifyContent: {
      type: String,
      default: null
    },
    alignItems: {
      type: String,
      default: null
    }
  },
  computed: {
    classes: function() {
      return {
        'flex-container--wrap': this.wrap,
        'flex-container--row':
          !this.flexDirection || this.flexDirection === 'row',
        'flex-container--row-reverse': this.flexDirection === 'row-reverse',
        'flex-container--column': this.flexDirection === 'column',
        'flex-container--column-reverse':
          this.flexDirection === 'column-reverse',
        'flex-container--justify-content-flex-start':
          this.justifyContent === 'flex-start',
        'flex-container--justify-content-center':
          this.justifyContent === 'center',
        'flex-container--align-items-center': this.alignItems === 'center',
        'flex-container--justify-content-flex-end':
          this.justifyContent === 'flex-end',
        'flex-container--align-items-flex-end': this.alignItems === 'flex-end',
        'flex-container--force-padding': this.forcePadding,
        'flex-container--padding': this.padding === 'normal',
        'flex-container--padding-small': this.padding === 'small',
        'flex-container--padding-large': this.padding === 'large',
        'flex-container--padding-horizontal':
          this.paddingHorizontal === 'normal',
        'flex-container--padding-horizontal-small':
          this.paddingHorizontal === 'small',
        'flex-container--padding-horizontal-large':
          this.paddingHorizontal === 'large',
        'flex-container--padding-vertical': this.paddingVertical === 'normal',
        'flex-container--padding-vertical-small':
          this.paddingVertical === 'small',
        'flex-container--padding-vertical-large':
          this.paddingVertical === 'large'
      }
    }
  }
}
</script>

<style lang="scss">
.flex-container {
  display: flex;
  flex: 1;
  max-width: 100%;
  justify-content: space-between;

  &--wrap {
    flex-wrap: wrap;
  }

  &--row-reverse {
    flex-direction: row-reverse;
  }

  &--column {
    flex-direction: column;
  }

  &--column-reverse {
    flex-direction: column-reverse;
  }

  &--justify-content-flex-start {
    justify-content: flex-start;
  }

  &--justify-content-center {
    justify-content: center;
  }

  &--align-items-center {
    align-items: center;
  }

  &--justify-content-flex-end {
    justify-content: flex-end;
  }

  &--align-items-flex-end {
    align-items: flex-end;
  }

  &--padding-horizontal-small {
    padding-left: $spacing-s;
    padding-right: $spacing-s;
  }

  &--padding-horizontal {
    padding-left: $spacing-m;
    padding-right: $spacing-m;
  }

  &--padding-horizontal-large {
    padding-left: $spacing-l;
    padding-right: $spacing-l;
  }

  &--padding-vertical-small {
    padding-top: $spacing-s;
    padding-bottom: $spacing-s;
  }

  &--padding-vertical {
    padding-top: $spacing-m;
    padding-bottom: $spacing-m;
  }

  &--padding-vertical-large {
    padding-top: $spacing-l;
    padding-bottom: $spacing-l;
  }

  &--padding-small {
    padding: $spacing-s;
  }

  &--padding {
    padding: $spacing-m;
  }

  &--padding-large {
    padding: $spacing-l;
  }

  &--row,
  &--row-reverse {
    > .flex-item__padding--small {
      padding: $spacing-s;

      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        padding-right: 0;
      }
    }

    > .flex-item__padding {
      padding: $spacing-m;

      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        padding-right: 0;
      }
    }

    > .flex-item__padding--large {
      padding: $spacing-l;

      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        padding-right: 0;
      }
    }

    > .flex-item__padding--small-horizontal {
      padding: 0 $spacing-s;

      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        padding-right: 0;
      }
    }

    > .flex-item__padding--horizontal {
      padding: 0 $spacing-m;

      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        padding-right: 0;
      }
    }

    &.flex-container--force-padding {
      > .flex-item__padding--small {
        padding-left: $spacing-s;
        padding-right: $spacing-s;
      }

      > .flex-item__padding {
        padding-left: $spacing-m;
        padding-right: $spacing-m;
      }

      > .flex-item__padding--large {
        padding-left: $spacing-l;
        padding-right: $spacing-l;
      }
    }
  }

  &--column,
  &--column-reverse {
    > .flex-item__padding--small {
      padding: $spacing-s;

      &:first-of-type {
        padding-top: 0;
      }

      &:last-of-type {
        padding-bottom: 0;
      }
    }

    > .flex-item__padding {
      padding: $spacing-m;

      &:first-of-type {
        padding-top: 0;
      }

      &:last-of-type {
        padding-bottom: 0;
      }
    }

    > .flex-item__padding--large {
      padding: $spacing-l;

      &:first-of-type {
        padding-top: 0;
      }

      &:last-of-type {
        padding-bottom: 0;
      }
    }

    > .flex-item__padding--small-horizontal {
      padding: 0 $spacing-s;
    }

    &.flex-container--force-padding {
      > .flex-item__padding--small {
        padding-top: $spacing-s;
        padding-bottom: $spacing-s;
      }

      > .flex-item__padding {
        padding-top: $spacing-m;
        padding-bottom: $spacing-m;
      }

      > .flex-item__padding--large {
        padding-top: $spacing-l;
        padding-bottom: $spacing-l;
      }
    }
  }

  @include respond-below(sm) {
    flex-direction: column;

    .flex-item {
      padding: 0;
    }
  }
}
</style>
