<template>
  <div class="input">
    <input
      type="email"
      class="input__element"
      :disabled="disabled"
      :value="value"
      placeholder="Enter your email"
      :autocomplete="autocomplete"
      @keyup.enter="step = 'password'"
      @change="$emit('change', $event.target.value)"
      @input="$emit('input', $event.target.value)"
    />
    <i v-if="false && value && !hasError" class="fal fa-check-circle" />
    <i v-if="false && hasError" class="fal fa-times-circle" />
    <p v-if="hasError" class="error">
      {{ errorMsg }}
    </p>
  </div>
</template>

<script>
import TextInput from '@/components/global/form/TextInput.vue'
export default {
  extends: TextInput
}
</script>
