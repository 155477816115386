<template>
  <div class="divider" :class="classes">
    <span v-if="hasSlot" class="divider__text">
      <slot />
    </span>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      validator: function(value) {
        return (
          ['primary', 'secondary', 'tertiary', 'black'].indexOf(value) !== -1
        )
      },
      default: 'primary'
    }
  },
  computed: {
    hasSlot() {
      return !!this.$slots.default || !!this.$scopedSlots.default
    },
    classes: function() {
      return {
        'divider--primary': this.type === 'primary',
        'divider--secondary': this.type === 'secondary',
        'divider--tertiary': this.type === 'tertiary',
        'divider--white': this.type === 'white'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.divider {
  color: $color-black;
  width: 80%;
  margin: 0 auto;
  text-align: center;

  &:before,
  &:after {
    content: '';
    width: 4rem;
    margin: 0.5rem 0;
    display: inline-block;
    border-bottom: 1px solid $color-black;
  }

  &--primary {
    color: $color-primary;

    &:before,
    &:after {
      border-bottom: 1px solid $color-primary;
    }
  }

  &--secondary {
    color: $color-secondary;

    &:before,
    &:after {
      border-bottom: 1px solid $color-secondary;
    }
  }

  &--tertiary {
    color: $color-tertiary;

    &:before,
    &:after {
      border-bottom: 1px solid $color-tertiary;
    }
  }

  &--white {
    color: $color-white;

    &:before,
    &:after {
      border-bottom: 1px solid $color-white;
    }
  }

  &__text {
    font-weight: 300;
    line-height: 2.2rem;
    letter-spacing: 0.05em;
    text-decoration: none;
    text-transform: uppercase;
    padding: 0 1rem;
  }
}
</style>
