<template>
  <div v-if="initialised" id="app">
    <div id="wrapper">
      <section class="main-content">
        <transition name="fade" mode="out-in">
          <router-view :key="$route.fullPath" />
        </transition>
      </section>
      <Footer />
    </div>
    <vue-progress-bar></vue-progress-bar>
  </div>
</template>

<script>
import Footer from '@/components/footer/Footer'
export default {
  components: {
    Footer
  },
  data: function() {
    return {
      initialised: false
    }
  },
  mounted() {
    //  [App.vue specific] When App.vue is finish loading finish the progress bar
    this.$Progress.finish()
  },
  async created() {
    //  [App.vue specific] When App.vue is first loaded start the progress bar
    console.log('PROGRESS', this.$Progress)
    this.$Progress.start()
    await this.$store.dispatch('journeys/openDBChannel')
    this.initialised = true
  }
}
</script>

<style lang="scss">
@import '@/assets/sass/base';

#wrapper {
  margin: 0 auto;
  min-height: 100vh;
  max-width: 100%;
  @include vendor('display', 'flex');
  @include vendor('flex-direction', 'column');
  @include vendor('align-itrems', 'center');
  @include vendor('justify-content', 'center');
  @include vendor('transition', 'opacity 1s ease-in-out');
}

.main-content {
  font-size: 1.6rem;
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: stretch;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
