<template>
  <footer class="footer">
    <div class="poweredby">
      <img
        class="turbine turbine--spin"
        src="~@/assets/images/turbine--dark.svg"
        width="18px"
      />
      Powered by renewables
    </div>
    <ul class="footer__links">
      <li>
        <router-link to="/privacy">
          Privacy Policy
        </router-link>
      </li>

      <li>
        <router-link to="/terms">
          Terms of Service
        </router-link>
      </li>
    </ul>
    <div class="copyright">&copy; Sustainabl. {{ thisYear }}</div>
  </footer>
</template>

<script>
export default {
  computed: {
    thisYear() {
      return new Date().getFullYear()
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  padding: 1.4rem 0 2.2em;
  text-align: center;
  width: 100%;
  color: $color-black;
  border-top: 1px solid $color-gray;
  font-size: 1.2rem;
  line-height: 1rem;

  li {
    display: inline-block;
    padding: 0 $padding-small;

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }
  }

  .icons .icon.alt {
    text-decoration: none;
    font-size: 3rem;
    color: #272833 !important;
    text-shadow: 1px 0 0 rgba(255, 255, 255, 0.5),
      -1px 0 0 rgba(255, 255, 255, 0.5), 0 1px 0 rgba(255, 255, 255, 0.5),
      0 -1px 0 rgba(255, 255, 255, 0.5);

    span {
      display: none;
    }
  }

  .footer__links {
    font-size: 1.2rem;
    line-height: 1rem;
    padding: $padding-small;
    text-align: center;

    li {
      border-left: solid 1px rgba(255, 255, 255, 0.3);
      display: inline-block;
      list-style: none;

      a {
        color: inherit;
      }
    }
  }
}

.turbine {
  position: relative;
  top: 4px;

  &--spin {
    animation-name: spin;
    animation-duration: 700ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
