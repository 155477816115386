<template>
  <div class="icon" :class="classes">
    <i :class="`fal fa-${icon}`" :style="{ fontSize }">
      <span v-if="hasSlot">
        <slot />
      </span>
    </i>
  </div>
</template>
<script>
export default {
  props: {
    icon: {
      type: String,
      default: 'link'
    },
    fontSize: {
      type: String,
      default: 'inherit'
    },
    type: {
      type: String
    }
  },
  computed: {
    hasSlot() {
      return !!this.$slots.default || !!this.$scopedSlots.default
    },
    classes: function() {
      return {
        'icon--primary': this.type === 'primary',
        'icon--secondary': this.type === 'secondary',
        'icon--tertiary': this.type === 'tertiary',
        'icon--quaternary': this.type === 'quaternary',
        'icon--quinary': this.type === 'quinary',
        'icon--success': this.type === 'success',
        'icon--error': this.type === 'error'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.icon {
  display: inline-block;
  text-align: center;
  cursor: default;

  i {
    position: relative;
    display: inline-block;
    color: $color-black;
    opacity: 0.8;
    outline: 0;
    text-decoration: none;
    border-bottom: none;
    @include vendor('transition', 'opacity .25s ease-in-out');

    &.icon:before {
      padding-right: 0;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      bottom: -0.75em;
      margin-left: -0.5em;
      border-bottom: solid 0em #ffffff;
      border-left: solid 0.5em transparent;
      border-right: solid 0.5em transparent;
      @include vendor('transition', 'border-bottom-width .25s ease-in-out');
    }

    span {
      // font-family: initial;
      font-family: 'Barlow', Helvetica, sans-serif;
      font-weight: 100;
      letter-spacing: 0;
    }
    //   display: block;
    //   position: absolute;
    //   color: #ffffff;
    //   bottom: -3rem;
    //   font-family: 'Source Sans Pro', sans-serif;
    //   font-size: 1.4rem;
    //   font-weight: 400;
    //   line-height: 2.25em;
    //   left: 50%;
    //   opacity: 0;
    //   @include vendor('transition', 'opacity .25s ease-in-out');
    //   // Labels not wide enough? Increase its width below and set margin-left to ((width / 2) * -1)
    //   width: 5.5em;
    //   margin-left: -2.75em;
    // }

    &:hover {
      opacity: 1;

      span {
        opacity: 1;
      }
    }

    &.active,
    &.router-link-exact-active {
      opacity: 1;

      span {
        opacity: 1;
      }
    }
  }

  &--primary i {
    color: $color-primary;
  }

  &--secondary i {
    color: $color-secondary;
  }

  &--tertiary i {
    color: $color-tertiary;
  }

  &--quaternary i {
    color: $color-tertiary;
  }

  &--quinary i {
    color: $color-quinary;
  }

  &--success i {
    color: $color-success;
  }

  &--error i {
    color: $color-error;
  }
}
</style>
