<template>
  <Field label="Price">
    <SliderInput
      v-model="value"
      class="slider-input"
      :enable-cross="false"
      :interval="10"
      :min-range="500"
      :min="min"
      :max="max"
      tooltip="always"
      tooltip-placement="top"
      :tooltip-formatter="'£{value}'"
      :contained="true"
      :lazy="true"
      @change="setPriceFilter(value)"
    />
  </Field>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data: function() {
    return {
      value: [0, 99990],
      min: 0,
      max: 99990
    }
  },
  computed: {
    ...mapGetters({
      getProductsMinAndMaxPrice: 'product/getProductsMinAndMaxPrice'
    })
  },
  watch: {
    getProductsMinAndMaxPrice: {
      immediate: true,
      handler(value) {
        if (value[0] !== null && value[1] !== null) {
          // this.value = value;
          // this.min = value[0];
          // this.max = value[1];
        }
      }
    }
  },
  mounted() {
    this.value = this.getProductsMinAndMaxPrice
    this.min = this.getProductsMinAndMaxPrice[0]
    this.max = this.getProductsMinAndMaxPrice[1]
  },
  methods: {
    ...mapActions({
      setPriceFilter: 'product/setPriceFilter'
    })
  }
}
</script>

<style lang="scss" scoped>
.slider-input {
  margin-top: 5rem;
}
</style>
