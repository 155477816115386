<template>
  <transition v-if="show" name="modal">
    <div class="modal" @click="closeModal($event)">
      <div class="modal__container">
        <Card>
          <div class="modal__close">
            <i class="fal fa-times" />
          </div>
          <div class="modal__content">
            <div class="modal-header">
              <slot name="header" />
            </div>
            <div class="modal-body">
              <slot name="body" />
            </div>
            <div v-if="!!this.$slots['footer']" class="modal-footer">
              <slot name="footer" />
            </div>
          </div>
        </Card>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    show: Boolean
  },
  watch: {
    show: function() {
      const elBody = document.documentElement
      if (this.show) {
        elBody.style.overflow = 'hidden'
      } else {
        elBody.style.overflow = 'unset'
      }
    }
  },
  methods: {
    closeModal(event) {
      if (
        event.target.classList.contains('modal') ||
        event.target.classList.contains('modal__close')
      ) {
        this.$emit('closeModal', false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  display: block;
  background: rgba(0, 0, 0, 0.4);
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  width: 100%;
  height: 100%;
  outline: 0;
}

.modal__container {
  max-width: 50vw;
  margin: $margin-large auto;
  position: relative;
  width: auto;
}

.modal__close {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  right: 0;
  margin-top: $margin-small;
  margin-right: $margin-small;

  &:hover {
    cursor: pointer;
  }
}

.modal-header {
  padding: 0 $padding;
  margin-bottom: $margin;
}

.modal-body {
  padding: $padding;
}

.modal-footer {
  padding: 0 $padding;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
