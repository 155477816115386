<template>
  <div class="icon-link" :class="classes">
    <router-link v-if="to" :to="to" :class="`fal fa-${icon}`">
      <span v-if="hasSlot">
        <slot />
      </span>
    </router-link>

    <a v-if="!to" :class="`fal fa-${icon}`">
      <span v-if="hasSlot">
        <slot />
      </span>
    </a>
  </div>
</template>
<script>
export default {
  props: {
    to: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: 'link'
    },
    invisible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes() {
      return {
        'icon-link--invisible': this.invisible
      }
    },
    hasSlot() {
      return !!this.$slots.default || !!this.$scopedSlots.default
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-link {
  display: inline-block;
  font-size: inherit;
  text-align: center;
  cursor: default;

  a {
    position: relative;
    display: inline-block;
    color: $color-black;
    opacity: 0.8;
    outline: 0;
    text-decoration: none;
    border-bottom: none;
    @include vendor('transition', 'opacity .25s ease-in-out');

    &.icon:before {
      padding-right: 0;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      bottom: -0.75em;
      margin-left: -0.5em;
      border-bottom: solid 0em #ffffff;
      border-left: solid 0.5em transparent;
      border-right: solid 0.5em transparent;
      @include vendor('transition', 'border-bottom-width .25s ease-in-out');
    }

    span {
      // font-family: initial;
      font-family: 'Barlow', Helvetica, sans-serif;
      font-weight: 100;
      letter-spacing: 0;
    }
    //   display: block;
    //   position: absolute;
    //   color: #ffffff;
    //   bottom: -3rem;
    //   font-family: 'Source Sans Pro', sans-serif;
    //   font-size: 1.4rem;
    //   font-weight: 400;
    //   line-height: 2.25em;
    //   left: 50%;
    //   opacity: 0;
    //   @include vendor('transition', 'opacity .25s ease-in-out');
    //   // Labels not wide enough? Increase its width below and set margin-left to ((width / 2) * -1)
    //   width: 5.5em;
    //   margin-left: -2.75em;
    // }

    &:hover {
      opacity: 1;

      span {
        opacity: 1;
      }
    }

    &.active,
    &.router-link-exact-active {
      opacity: 1;

      span {
        opacity: 1;
      }
    }
  }

  &--invisible {
    a {
      opacity: 0;

      span {
        opacity: 0;
      }

      &:hover {
        opacity: 1;
      }
    }
  }
}
</style>
