const state = {
  isBurgerMenuOpen: false
}

const mutations = {
  toggleBurgerMenuState(state) {
    state.isBurgerMenuOpen = !state.isBurgerMenuOpen
  },
  falsyBurgerMenuState(state) {
    state.isBurgerMenuOpen = false
  }
}

const actions = {
  toggleMobileMenu({ commit, state }) {
    commit('toggleBurgerMenuState')
    const siteHtml = document.getElementsByTagName('html')[0]
    state.isBurgerMenuOpen
      ? siteHtml.classList.add('mobile-nav--open')
      : siteHtml.classList.remove('mobile-nav--open')
  },
  routeChange({ commit }) {
    commit('falsyBurgerMenuState')

    const siteHtml = document.getElementsByTagName('html')[0]
    siteHtml.classList.remove('mobile-nav--open')
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
