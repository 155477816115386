<template>
  <section class="item" :class="classes">
    <figure>
      <img v-if="src" :src="src" />
      <div v-else class="item__no-image">
        <i class="fal fa-image" />
      </div>
    </figure>
    <article>
      <h2 v-if="title">
        {{ title }}
      </h2>
      <slot />
    </article>
  </section>
</template>

<script>
export default {
  props: {
    flexDirection: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    src: {
      type: String,
      default: null
    }
  },
  computed: {
    classes() {
      return {
        'item--row-reverse': this.flexDirection === 'row-reverse',
        'item--column': this.flexDirection === 'column',
        'item--column-reverse': this.flexDirection === 'column-reverse'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
  display: flex;
  text-align: center;
  color: $color-black;
  line-height: 2rem;
  margin: $margin-large 0;

  figure {
    width: 40%;
    height: 100%;
    margin-right: $margin-small;

    @include respond-below(sm) {
      width: 100%;
      margin: 0;
    }
  }

  img {
    width: 100%;
    // height: 90px;
    border-radius: $border-radius;
  }

  .item__no-image {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 5rem;
    color: $color-white;
    width: 100%;
    height: 200px;
    border-radius: $border-radius;
    background-size: cover;
    background-image: url('~@/assets/overlay4.svg'),
      linear-gradient(45deg, #4361c2, #4fa49a 50%, #9dc66b 95%);
    background-position: top left, center center, center center;
  }

  article {
    width: 60%;
    margin-left: $margin-small;

    @include respond-below(sm) {
      width: 100%;
    }
  }

  h2 {
    font-size: 1.6rem;
    margin-bottom: 3.2rem;
    font-weight: 400;
    letter-spacing: 0.125em;
    text-align: left;
    text-decoration: none;
    text-transform: uppercase;
  }

  p {
    margin-bottom: 1rem;
    line-height: 3.2rem;
    font-size: 2.2rem;
    font-weight: 300;
    text-align: left;

    &:last-of-type {
      margin-bottom: 3.2rem;
    }
  }

  &--row-reverse {
    flex-direction: row-reverse;

    figure {
      margin-right: 0;
      margin-left: $margin-small;

      @include respond-below(sm) {
        margin: 0;
      }
    }

    article {
      margin-left: 0;
      margin-right: $margin-small;

      @include respond-below(sm) {
        margin: 0;
      }
    }
  }

  &--column {
    flex-direction: column;

    figure {
      width: 100%;
      margin: 0;
      margin-bottom: $margin;
    }

    article {
      margin: 0;
      width: 100%;
    }
  }

  &--column-reverse {
    flex-direction: column-reverse;

    figure {
      width: 100%;
      margin: 0;
    }

    article {
      margin: 0;
      width: 100%;
      margin-bottom: $margin;
    }
  }

  @include respond-below(sm) {
    display: block;
  }
}
</style>
