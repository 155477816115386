<template>
  <div class="field" :class="classes">
    <label v-if="label" class="field__label" :class="labelClasses">{{
      label
    }}</label>
    <div v-if="!label && !noLabel" class="field__label-block" />
    <div :class="labelClasses">
      <slot />
    </div>
    <p v-if="vError" class="field__error">
      Theres something wrong with this field
    </p>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: null
    },
    noLabel: {
      type: [String, Boolean],
      default: null
    },
    vError: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    },
    align: {
      type: String,
      validator: function(value) {
        return ['left', 'center', 'right'].indexOf(value) !== -1
      },
      default: 'left'
    },
    type: {
      type: String,
      validator: function(value) {
        return (
          ['primary', 'secondary', 'tertiary', 'black'].indexOf(value) !== -1
        )
      },
      default: 'primary'
    }
  },
  computed: {
    classes: function() {
      return {
        'field--primary': this.type === 'primary',
        'field--secondary': this.type === 'secondary',
        'field--tertiary': this.type === 'tertiary',
        'field--black': this.type === 'black',
        'field--align-right': this.align === 'right'
      }
    },
    labelClasses: function() {
      return {
        'field__label--inline': this.inline
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.field {
  font-size: 1.6rem;
  margin-bottom: $margin;
  width: 100%;

  &:last-of-type {
    margin-bottom: 0;
  }

  &--primary {
    color: $color-primary;
  }

  &--secondary {
    color: $color-secondary;
  }

  &--tertiary {
    color: $color-tertiary;
  }

  &--black {
    color: $color-black;
  }

  &--align-right {
    text-align: right;
  }
}

.field__label {
  display: block;
  font-weight: 700;
  letter-spacing: 0.125em;
  text-transform: uppercase;
  font-size: 1.2rem;
  line-height: 1.2rem;
  margin-bottom: $margin-small;

  &--inline {
    display: inline-block;
    padding: 0 0.8rem;
  }
}

.field__label-block {
  height: 2.2rem;
  margin-bottom: $margin-small;
}
</style>
