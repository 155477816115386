<template>
  <Field v-if="filterOptions.length > 1" :label="filterKey">
    <CheckboxInput
      v-for="option in shownOptions"
      :key="option"
      @onClick="toggleFilter(filterKey, option)"
    >
      {{ option }}
    </CheckboxInput>
    <a
      v-show="filterOptions.length > maxOptions"
      @click="maxOptions = filterOptions.length"
      >More</a
    >
    <a v-show="filterOptions.length === maxOptions" @click="maxOptions = 5"
      >Less</a
    >
  </Field>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    type: {
      type: String,
      default: ''
    },
    filterKey: {
      type: String,
      default: ''
    },
    filterOptions: {
      type: Array,
      default: () => []
    }
  },
  data: function() {
    return {
      maxOptions: 5
    }
  },
  computed: {
    shownOptions() {
      return this.filterOptions.slice(0, this.maxOptions)
    }
  },
  methods: {
    ...mapActions({
      toggleProductFilter: 'product/toggleFilter'
    }),
    toggleFilter(key, value) {
      this.toggleProductFilter({
        key,
        operator: '=',
        value
      })
    }
  }
}
</script>
