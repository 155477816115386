<template>
  <div class="input">
    <input
      type="text"
      autocomplete="off"
      :name="name"
      class="input__element"
      :value="search"
      :placeholder="placeholder"
      @change="onChange"
      @input="onChange"
      @focus="onChange"
      @blur="showResults = false"
    />
    <ul v-show="showResults" class="input__results">
      <li
        v-for="(result, index) in results"
        :key="index"
        class="input__result"
        @mousedown.prevent="setResult(result)"
      >
        {{ result }}
      </li>
      <li class="input__button">
        <Button type="secondary" size="small" @onMousedown="addNewResult">
          Add new value
        </Button>
      </li>
    </ul>
    <i v-if="value && !hasError" class="fal fa-check-circle" />
    <i v-if="hasError" class="fal fa-times-circle" />
    <p v-if="hasError" class="error">
      {{ errorMsg }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: 'input'
    },
    inputChanged: {
      type: Function,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      value: false
    },
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    hasError: {
      type: Boolean
    },
    errorMsg: {
      type: String,
      default: null
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  data: function() {
    return {
      search: this.value,
      results: [],
      showResults: false
    }
  },
  methods: {
    onChange($event) {
      this.search = $event.target.value
      this.showResults = true
      this.results = this.items.filter(
        item => item.toLowerCase().indexOf(this.search.toLowerCase()) > -1
      )
    },
    setResult(result) {
      this.search = result
      this.showResults = false
      this.$emit('input', result)
    },
    addNewResult() {
      if (this.search.length) {
        this.$emit('input', this.search)
        this.showResults = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.input {
  position: relative;

  .fal {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 1.6rem;
    padding: 0.8em 1.6rem;
    line-height: 1.9rem;
    color: $color-secondary;

    &.fa-check-circle {
      color: $color-tertiary;
    }
  }

  .error {
    margin: 2px 0;
    color: $color-secondary;
  }
}

.input__element,
.input__results {
  outline: none;
  display: block;
  background: rgba(255, 255, 255, 0.4);
  width: 100%;
  border: 1px solid $color-gray;
  padding: 1.6rem;
  line-height: 1.6rem;
  height: 4.8rem;
  font-size: 1.6rem;
  color: $color-gray--darker;
  border-radius: $border-radius-small;

  &:disabled {
    color: $color-gray;
  }
}

.input__results {
  position: absolute;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  background: $color-white;
  height: auto;
  padding: 0;
  z-index: 1;
}

.input__result {
  padding: 1.6rem;

  &:hover {
    background: $color-gray--lighter;
  }
}

.input__button {
  padding: 0.8rem;
}
</style>
