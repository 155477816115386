<template>
  <div class="card" :class="classes">
    <div class="card__content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    shadow: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes: function() {
      return {
        'card--shadow': this.shadow,
        'card--inner-gradient': this.innerGradient
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;

  &--shadow {
    box-shadow: 0 2px 6px 0 rgba(#000000, 0.25);
  }

  @include respond-above(md) {
    align-items: stretch;
    margin-bottom: 36px;
  }
}
</style>
