<template>
  <button class="button" :class="classes" type="submit" @click="handleClick">
    <i v-if="icon" :class="`fal fa-${icon}`" /><span
      :class="icon ? 'u__margin-left' : ''"
      ><slot>Submit</slot></span
    >
  </button>
</template>

<script>
import Button from '@/components/global/ui/Button.vue'
export default {
  name: 'ButtonSubmit',
  extends: Button
}
</script>
