import { firebase, googleAuthProvider } from '../../firebase'
import { arrayUnion } from 'vuex-easy-firestore'
import router from '../../router'

const state = {
  currentUser: null,
  error: null
}

const mutations = {
  clearUser(state) {
    state.currentUser = {}
  },
  setError(state, error) {
    state.error = error.message
  },
  clearErrors(state) {
    state.error = null
  }
}

const actions = {
  async createUser({ dispatch, commit }, userInfo) {
    commit('clearErrors')
    firebase
      .auth()
      .createUserWithEmailAndPassword(userInfo.email, userInfo.password)
      .then(async function(response) {
        const user = response.user
        await dispatch('setUserId', user.uid)

        dispatch('set', {
          name: userInfo.name,
          department: userInfo.department,
          admin: false,
          levelsCompleted: [],
          completedChallenges: [],
          activeChallenges: [],
          hasOnboarded: false
        })
      })
      .catch(function(error) {
        commit('setError', error)
      })
  },
  async emailPasswordSignIn({ dispatch, commit }, { email, password }) {
    commit('clearErrors')
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(async function(response) {
        const user = response.user
        await dispatch('setUserId', user.uid)
        // dispatch('fetchAndAdd')
        router.push('/')
      })
      .catch(function(error) {
        commit('setError', error)
      })
  },
  async googleSignIn({ dispatch, commit }) {
    commit('clearErrors')
    try {
      const { user } = await firebase.auth().signInWithPopup(googleAuthProvider)
      dispatch('setUserId', user.uid)
      dispatch('fetchAndAdd')
      router.push('/')
      // dispatch('set', user.displayName);
    } catch (error) {
      commit('setError', error)
    }
  },
  async signOut({ dispatch, commit }) {
    commit('clearErrors')
    try {
      await firebase.auth().signOut()
      dispatch('clearUser')
      commit('clearUser')
      router.push('/login')
    } catch (error) {
      commit('setError', error)
    }
  },
  async editUser({ dispatch }, user) {
    dispatch('patch', user)
  },
  async onboardUser({ dispatch }, user) {
    dispatch('patch', user)
    router.push('/')
  },
  async startChallenge({ dispatch }, challengeId) {
    dispatch('patch', {
      activeChallenges: arrayUnion(challengeId)
    })
  },
  async completeChallenge({ dispatch }, challengeId) {
    dispatch('patch', {
      completedChallenges: arrayUnion(challengeId)
    })
  },
  async resetUser({ dispatch, state }) {
    const user = state.currentUser
    user.levelsCompleted = []
    user.completedChallenges = []
    user.activeChallenges = []
    user.hasOnboarded = false
    dispatch('patch', user)
  },
  clearErrors({ commit }) {
    commit('clearErrors')
  }
}

const getters = {
  isSignedIn: state => {
    if (state._sync.signedIn && state.currentUser && state.currentUser.id) {
      return true
    }
    return false
  },
  isAdmin: state => {
    if (state.currentUser && state.currentUser.admin) {
      return true
    }
    return false
  },
  isVerified: state => {
    if (state.currentUser && state.currentUser.verified) {
      return true
    }
    return false
  },
  hasOnboarded: state => {
    if (state.currentUser && state.currentUser.hasOnboarded) {
      return true
    }
    return false
  },
  getUserRoles: state => {
    if (!state.user || !state.user.roles) {
      return []
    }
    return state.user.roles
  },
  getUser: state => {
    return state.currentUser
  }
}

export default {
  firestorePath: 'user/{userId}',
  firestoreRefType: 'doc',
  moduleName: 'user',
  statePropName: 'currentUser',
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
